import React from "react";
import styles from "./OnCallProtocol.module.scss";
import { DataService } from "../../store/DataService";
import { CALLTYPES } from "../../AppConstants";
import GenConfirm from "./../../components/GenConfirm";

var nextproviderid = 0;

const OnCallProtocol = (props) => {
    const [matrix,setMatrix] = React.useState({ERROR:'',provs:[],ocplist:[],methods:[],contacttypes:[]});
    const [currentproviderid,setCurrentProviderID] = React.useState(0);
    const [currentstart,setCurrentStart] = React.useState("00:00");
    const [range,setRange] = React.useState({start:"12:00 AM",end:"23:59 PM"});
    const [showdirty,setShowDirty] = React.useState(false);
    const [scrolltop,setScrollTop] = React.useState(false);
    const [currentmethods,setCurrentMethods] = React.useState([]);
    const tmref = React.useRef(null);

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.oncallprotocolinit(props.globals.practiceid).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data);
            let methods = [];
            let secs = 0;
            let starttm = "12:00 AM";
            let endtm = "23:59 PM";
            data.ocplist.forEach((obj)=> {
                if (obj.providerid===0) {
                    methods.push(JSON.parse(JSON.stringify(obj)));
                    if (secs >= obj.startsecs && secs <= obj.endsecs) {
                        starttm = displayTM(obj.startsecs);
                        endtm = displayTM(obj.endsecs);
                    }
                }
            })
            setCurrentMethods(methods);
            setRange({start:starttm,end:endtm});
        });
    },[props.globals.sessionguid,props.globals.practiceid]);



    const tmSeconds = (tm) => {
        tm = "" + tm;
        let sa = tm.split(':')
        let secs = 0;
        let hr = parseInt(sa[0]);
        let mn = 0;
        let ampm = "AM";
        
        if (sa.length > 1) {
            let smn = sa[1];
            let sma = smn.split(' ');
            if (sma.length > 1) {
                ampm = sma[1];
            } else {
                if (hr > 11) ampm = "PM";
            }
            if (ampm==="PM" && hr < 12) hr += 12;
            mn = parseInt(sma[0]);

        }
        secs = (hr * 3600) + (mn * 60);
        return secs;
    }

    const secondsTM = (secs) => {
        let tm = "";
        let hr = parseInt(secs / 3600);
        let mn = parseInt((secs - (hr * 3600)) / 60);
        if (mn < 0) mn = 0;
        if (mn < 10) {
            mn = "0" + mn;
        } else {
            mn = "" + mn;
        }
        tm = hr + ":" + mn
        return tm;
    }

    const displayTM = (secs) => {
        let tm = "";
        let hr = parseInt(secs / 3600);
        let mn = parseInt((secs - (hr * 3600)) / 60);
        let ampm = "AM";
        if (hr > 11) {
            ampm = "PM";
            if (hr > 12) hr -= 12;
        }
        if (mn < 0) mn = 0;
        if (mn < 10) {
            mn = "0" + mn;
        } else {
            mn = "" + mn;
        }
        if (hr===0) hr = 12;
        tm = hr + ":" + mn + " " + ampm;
        return tm;
    }

    const findOCP = (providerid) => {
        let ocps = [];
        let provid = "" + providerid;
        matrix.ocplist.forEach((ocpobj)=> {
            let prid = "" + ocpobj.providerid;
            
            if (prid===provid) {
                //let newobj = JSON.parse(JSON.stringify(ocpobj));
                ocpobj.toprow = true;
                ocps.push(ocpobj);
            }
        })
        
        return ocps;
    }

    const setCurrentRange = (e) => {
        const name=e.target.attributes.name.value;
        //alert("Range here "+name);
        const sa = name.split("_");
        if (sa.length > 1) {
            const secs = parseInt(sa[1]);
            //const olist = findOCP(currentproviderid);
            
            let newlist = JSON.parse(JSON.stringify(currentmethods));
            let obj = {};
            let found = false;
            let index = -1;
            
            let laterindex = -1;
            let lastsecs = 86400;
            for (let i=0;i<newlist.length;i++) {
                obj = newlist[i];
                if (secs >= obj.startsecs && secs <= obj.endsecs) {
                    index = i;
                    found = true;
                }
                if (secs < obj.startsecs && obj.startsecs <= lastsecs) {
                    laterindex = i;
                    lastsecs = obj.startsecs;
                }
                
            }
            if (found) {
                obj = newlist[index];
                obj.endsecs = secs-60;
                obj.endtime = secondsTM(obj.endsecs);
                obj.selected = false;
            }
            let currentmin = 0;
            // create negative id for saving later as new protocol
            newlist.forEach((ocp)=> {
                let ocpid = parseInt(ocp.oncallprotocolid);
                if (ocpid < currentmin) currentmin = ocpid;
            })
            currentmin -= 1;
            obj = {
                oncallprotocolid:"" + currentmin,
                startsecs:secs,
                starttime:secondsTM(secs),
                endsecs:lastsecs - 60,
                endtime:secondsTM(lastsecs - 60),
                dirty:true,
                toprow:true,
                selected:false,
                providerid:currentproviderid,
                practiceid:props.globals.practiceid,
                steps:[]
            }
            setRange({start:displayTM(obj.startsecs),end:displayTM(obj.endsecs)});
            setCurrentStart(obj.starttime);
            newlist.push(obj);
            
            let tempocp = JSON.parse(JSON.stringify(matrix.ocplist));
            let newocp = [];
            tempocp.forEach((ocp)=> {
                let provid = "" + ocp.providerid;
                if (provid !== currentproviderid) {
                    newocp.push(ocp);
                }
            })
            let newocp2 = newocp.concat(newlist);
            setMatrix(values=>({...values,ocplist:newocp2}));
            setCurrentMethods(newlist);
        }
    }


    const deleteRange = (e) => {
        e.preventDefault();
        const sname = e.target.parentElement.attributes.name.value;
        const sa = sname.split("_");
        
        if (sa.length > 1) {
            const secs = parseInt(sa[1]);
            let newmethods = [];
            let newstartsec = 0;
            let newendsec = 24 * 3600 - 60;
            currentmethods.every((method)=> {
                if (secs >= method.startsecs && secs <= method.endsecs) {
                    newstartsec = method.startsecs - 60;
                    newendsec = method.endsecs;
                    return false;
                }
                return true;
            })
            

            currentmethods.forEach((method)=> {
                if (method.startsecs <= secs && method.endsecs >= secs) {

                } else {
                    if (method.endsecs === newstartsec) {
                        method.endsecs = newendsec;
                    }
                    method.dirty = true;
                    newmethods.push(JSON.parse(JSON.stringify(method)));
                }
            })
            let tempocp = JSON.parse(JSON.stringify(matrix.ocplist));
            let newocp = [];
            tempocp.forEach((ocp)=> {
                let provid = "" + ocp.providerid;
                if (provid!==currentproviderid) {
                    newocp.push(ocp);
                } 
            })
            let newocp2 = newocp.concat(newmethods);
            setMatrix(values=>({...values,ocplist:newocp2}));
            setCurrentMethods(newmethods);
            setZeroStart();
            
        }

    }

    const setStart = (e) => {
        if (e.target.attributes.name===undefined) return;
        const name=e.target.attributes.name.value;
        //alert("SetStart here "+name);
        const sa = name.split("_");
        if (sa.length > 1) {
            const secs = parseInt(sa[1]);
            const disptm = displayTM(secs);            
            setCurrentStart(secondsTM(secs));
            const olist = findOCP(currentproviderid);
            let starttm = "12:00 AM";
            let endtm = "23:59 PM";
            if (olist.length) {
                for (let i=0;i<olist.length;i++) {
                    let obj = olist[i];
                    if (secs >= obj.startsecs && secs <= obj.endsecs) {
                        starttm = displayTM(obj.startsecs);
                        endtm = displayTM(obj.endsecs);
                    }
                }
            }
            setRange({start:starttm,end:endtm});
        }
    }

    const ShowTimeline = () => {
        const colors = ["lightgreen","lightblue","rgb(245, 201, 119)","rgb(168, 129, 168)","lightyellow","rgb(175, 120, 120)","lightred"];
        const olist = findOCP(currentproviderid);
        let tmline = [];
        let seconds = 0;
        let selectedseconds = tmSeconds(currentstart);
        for (let x=0;x<48;x++) {
            let s = "";
            let hr = x / 2;
            hr = hr > 12 ? hr-12 : hr;
            let mn = 0;
            if (x===0) {
                s = "AM 12:00";
            } else {
                if (x === 24) {
                    s = "PM 12:00";
                } else {
                    if (x & 1) {
                        mn = 30;
                        s = ":30";
                    } else {
                        s = hr + ":00";
                    }
                }
            }
            let bgcolor = "white";
            let txtcolor = "black";
            let txtbgcolor = "white";
            let toprow = false;
            for (let i=0;i<olist.length;i++) {
                let obj = olist[i];
                
                if (seconds >= obj.startsecs && seconds <= obj.endsecs) {
                    let count = i;
                    if (count >= colors.length) count = colors.length - 1;
                    bgcolor = colors[count];
                    toprow = obj.toprow;
                    obj.toprow = false;
                    if (selectedseconds >= obj.startsecs && selectedseconds <= obj.endsecs) {
                        bgcolor = "rgb(182, 142, 142)";
                        txtbgcolor = "lightgray";
                    }
                }
            }
            tmline.push({time:s,color:bgcolor,toprow:toprow,seconds:seconds,txtcolor:txtcolor,txtbgcolor:txtbgcolor});
            seconds += 1800;
        }

        return (
            <div className={styles.tmtable} id={"tmbox"} ref={tmref}>
                {tmline.map((obj,i) => {
                    return <React.Fragment key={"zz_"+i}>
                        <div key={"tm_"+i} className={styles.tmhours} style={{backgroundColor:obj.txtbgcolor,color:obj.txtcolor}}>{obj.time}</div>
                        <div name={"tm_"+obj.seconds} onClick={setStart} onDoubleClick={setCurrentRange} style={{backgroundColor:obj.color,borderBottom:"1px solid black",width:"100%",textAlign:"center"}}>
                            {obj.toprow && <button onClick={deleteRange} style={{height:"16px",width:"34px",fontSize:"12px",padding:"0",backgroundColor:"blue",color:"white"}} >Del</button>}
                        </div>
                    </React.Fragment>
                })}
            </div>
        )
    }

    const initMethods = (provid) => {
        const olist = findOCP(provid);
        let methods = [];
        olist.forEach((obj)=> {
            let newobj = JSON.parse(JSON.stringify(obj))
            newobj["dirty"] = false;
            newobj["oncallprotocolid"] = "" + newobj["oncallprotocolid"];
            newobj.steps.forEach((step)=> {
                step.oncallprotocolstepsid = "" + step.oncallprotocolstepsid;
            })
            methods.push(newobj);
        })
        setCurrentMethods(methods);

    }

    const setZeroStart = () => {
        setCurrentStart("00:00");
        tmref.current.scroll({
            top:0,
            behavior:"auto"
        });
    }

    const dirtyCancel = (e) => {
        setShowDirty(false);
    }

    const dirtyConfirm = (e) => {
        setShowDirty(false);        
        initMethods(nextproviderid);
        setCurrentProviderID(nextproviderid);
        setZeroStart();
    }

    const ShowDirty = () => {
        const txt = "You haven't saved your changes.\nSelect Cancel to go back and Save.\nSelect Confirm to disregard changes and continue.";
        return (
            <GenConfirm
                text={txt}
                width={400}
                onCancel={dirtyCancel}
                onConfirm={dirtyConfirm}
            />
        )
    }

    const isDirty = () => {
        let dirty = false;
        const olist = findOCP(currentproviderid);
        if (olist.length !== currentmethods.length) return true;
        currentmethods.every((obj)=> {
            dirty = obj.dirty;
            if (dirty) return false;
            return true;
        })
        
        return dirty;
    }

    const changeProvider = (e) => {
        const provid = e.target.value;
        if (isDirty()) {
            nextproviderid = provid;
            setShowDirty(true);
            return;
        }
        initMethods(provid);
        setCurrentProviderID(provid);
        setZeroStart();
    }

    const ShowProviders = () => {
        const provlist = matrix.providers || [];
        return (
            <div className={styles.provblock}>Protocol For: 
            <select name="selprov" style={{marginLeft:"10px",width:"200px",marginTop:"10px"}} value={currentproviderid} onChange={changeProvider}>
                <option value="0">Medical Practice</option>
                {provlist.map((obj,i)=> {
                    let provname = obj.lastname + ", " + obj.firstname;
                    if (obj.middleinitial.length) provname += " "+obj.middleinitial;
                    
                    return <option key={"popt_"+i} value={obj.providerid}>{provname}</option>
                })}
            </select>
            </div>
        )
    }

    const findMethods = (providerid) => {
        let mlist = [];
        let pid = "" + providerid;
        matrix.methods.forEach((method)=> {
            if (method.providerid===pid) {
                mlist.push(method);
            }
        })
        return mlist;
    }

    const findCurrentMethod = (methods) => {
        const secs = tmSeconds(currentstart);
        let method = null;
        methods.every((obj)=> {
            if (obj.startsecs <= secs && obj.endsecs >= secs) {
                method = obj;
                return false;
            }
            return true;
        })
        return method;
    }

    const objectsEqual = (o1, o2) => {
        return Object.keys(o1).length === Object.keys(o2).length 
            && Object.keys(o1).every(p => o1[p] === o2[p]);
    }

    const newStep = (method) => {
        let currentmin = 0;
        // create negative id for saving later as new step
        
        method.steps.forEach((step)=> {
            let stepid = parseInt(step.oncallprotocolstepsid);
            if (stepid < currentmin) {
                currentmin = stepid;
            }
        })
        currentmin -= 1;
        let obj = {
            oncallprotocolstepsid:""+currentmin,
            oncallprotocolid:method.oncallprotocolid,
            sequence:method.steps.length + 1,
            waittimeminutes:0,
            personphoneid:0,
            oncalltype:0,
            personid:0,
            contacttypeid:0,
            phonenumber:"",
            pageremail:""
        }
      
        return obj;
    }

    const newProtocol = (methods) => {
        let currentmin = 0;
        // create negative id for saving later as new protocol
        methods.forEach((ocp)=> {
            let ocpid = parseInt(ocp.oncallprotocolid);
            if (ocpid < currentmin) currentmin = ocpid;
        })
        currentmin -= 1;
        var obj = {
            oncallprotocolid:""+currentmin,
			starttime:"00:00",
            startsecs:0,
			endtime:"23:59",
            endsecs:86340,
            toprow:false,
            selected:false,
            dirty: true,
			providerid:currentproviderid,
			practiceid:props.globals.practiceid,
            steps:[]
        }
        return obj;
    }

    const addMethod = (e) => {
        let newmethods = JSON.parse(JSON.stringify(currentmethods));
        let currmethod = findCurrentMethod(newmethods);
        if (currmethod===null) {
            currmethod = newProtocol(newmethods);
            newmethods.push(currmethod);
        }
        currmethod.steps.push(newStep(currmethod));
        currmethod.dirty = true;
        setCurrentMethods(newmethods);
    }

    const saveChanges = (e) => {
        let newmethods = JSON.parse(JSON.stringify(currentmethods));
        let seqnum = 1;
        let isgood = true;
        newmethods.forEach((m)=> {
            seqnum = 1;
            let pid = parseInt(m.providerid);
            m.steps.forEach((stp)=> {
                let octid = parseInt(stp.oncalltype);
                if (stp.personphoneid < 1 && pid > 0) isgood = false;
                if (pid < 1 && octid < 1) isgood = false;
                stp.sequence = seqnum;
                seqnum += 1;
            })
        })
        if (!isgood) {
            alert('Cannot have blank Contact Method.\nPlease select contact method before saving.');
            return;
        }
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.oncallprotocolsave(props.globals.practiceid,currentproviderid,newmethods).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let newlist = [];

            let curprovid = parseInt(currentproviderid);
            matrix.ocplist.forEach((ocpobj)=> {
                let prid = parseInt(ocpobj.providerid);
                if (prid!==curprovid) {
                    newlist.push(ocpobj);
                }
            })
            let newmethods = JSON.parse(JSON.stringify(data.methods));
            newmethods.forEach((method)=> {
                method.oncallprotocolid = "" + method.oncallprotocolid;
                method.steps.forEach((step)=> {
                    step.oncallprotocolstepsid = "" + step.oncallprotocolstepsid;
                })
            })
            let newlist2 = newlist.concat(newmethods);
            setMatrix(values=>({...values,ocplist:newlist2}));
            setCurrentMethods(newmethods);
        })
    }

    const ShowSteps = () => {

        const deleteMethod = (e) => {
            var sa = e.target.name.split("_");
            if (sa.length > 2) {
                let newmethods = JSON.parse(JSON.stringify(currentmethods));
                let ocpid = "" + sa[1];
                let stepid = "" + sa[2];
                newmethods.every((obj)=> {
                    if (obj.oncallprotocolid===ocpid) {
                        let newsteps = [];
                        obj.steps.forEach((step)=> {
                            if (step.oncallprotocolstepsid !== stepid) {
                                newsteps.push(step);
                            }
                        })
                        obj.dirty = true;
                        obj.steps = newsteps;
                        setCurrentMethods(newmethods);
                        return false;
                    }
                    return true;
                })
            }
        }

        const findContactType = (ppid) => {
            let phoneid = "" + ppid;
            let ctid = '0';
            matrix.methods.every((method)=> {
                let mpid = "" + method.personphoneid;
                if (mpid===phoneid) {
                    ctid = method.contacttypeid;
                    return false;
                }
                return true;
            })
            return ctid;
        }

        const changeMethod = (e) => {
            var value = e.target.value;
            var sa = e.target.name.split("_");
            if (sa.length > 2) {
                let newmethods = JSON.parse(JSON.stringify(currentmethods));
                let ocpid = "" + sa[1];
                let stepid = "" + sa[2];
                newmethods.every((obj) => {
                    let opid = "" + obj.oncallprotocolid;
                    if (opid===ocpid) {
                        return obj.steps.every((step)=> {
                            if (step.oncallprotocolstepsid===stepid) {
                                if (currentproviderid < 1) {
                                    step.oncalltype = value;
                                } else {
                                    step.personphoneid = value;
                                    step.contacttypeid = findContactType(value);
                                }
                                obj.dirty = true;
                                setCurrentMethods(newmethods);
                                return false;
                            }
                            return true;
                        })
                    }
                    return true;
                })
            }
        }

        const changeWait = (e) => {
            var value = e.target.value;
            var sa = e.target.name.split("_");
            if (sa.length > 2) {
                let newmethods = JSON.parse(JSON.stringify(currentmethods));
                let ocpid = "" + sa[1];
                let stepid = "" + sa[2];
                newmethods.every((obj) => {
                    if (obj.oncallprotocolid===ocpid) {
                        return obj.steps.every((step) => {
                            if (step.oncallprotocolstepsid === stepid) {
                                step.waittimeminutes = value;
                                obj.dirty = true;
                                setCurrentMethods(newmethods);
                                return false;
                            }
                            return true;
                        })
                    }
                    return true;
                })
            }
        }

        let ctmatrix = {}
        matrix.contacttypes.forEach((c)=> {
            ctmatrix[""+c.contacttypeid] = c.description;
        })
        const olist = currentmethods; //findOCP(currentproviderid);
        const mlst = findMethods(currentproviderid);
        let steps = [];
        let secs = tmSeconds(currentstart);
        let ocpid = 0;
        for (let i=0;i<olist.length;i++) {
            let ocp = olist[i];
            if (ocp.startsecs <= secs && ocp.endsecs >= secs) {
                ocpid = ocp.oncallprotocolid;
                steps = ocp.steps;
                break;
            }
        }

        return (
            <div>
            <div className={styles.methods}>
                {steps.map((obj,i) => {
                    const oct = obj.oncalltype;
                    let showwait = true;
                    if (obj.contacttypeid ==='7') showwait = false;
                    const ppid = obj.personphoneid;
                    const stepid = obj.oncallprotocolstepsid;
                    let selvalue = 0;
                    let sellist = [];
                    if (currentproviderid < 1) {
                        sellist.push({value:3,name:CALLTYPES[3]});
                        sellist.push({value:4,name:CALLTYPES[4]});
                        sellist.push({value:6,name:CALLTYPES[6]});
                        selvalue = oct;
                    } else {
                        selvalue = ppid;
                        mlst.forEach((m)=> {
                            let mname = m.phonenumber;
                            if (m.contacttypeid==='5' || m.contacttypeid==='7') mname = m.pageremail;
                            let ctname = ctmatrix[m.contacttypeid] || "";
                            if (ctname.length) mname += " ("+ctname+")";
                            sellist.push({value:m.personphoneid,name:mname.trim()})
                        })
                    }
                    return (
                        <React.Fragment key={"rf_"+i}>
                            <div>
                                <select style={{width:"400px"}} name={"ocp_"+ocpid+"_"+stepid} value={selvalue} onChange={changeMethod}>
                                    <option value='0'></option>
                                    {sellist.map((selobj,i)=> {
                                        return <option value={selobj.value}>{selobj.name}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                {showwait && <input style={{width:"75px"}} type="text" name={"wt_"+ocpid+"_"+stepid} value={obj.waittimeminutes} onChange={changeWait}></input>}
                            </div>
                            <div>
                                <button name={"btn_"+ocpid+"_"+stepid} onClick={deleteMethod}>Delete</button>
                            </div>
                        </React.Fragment>
                    )
                })}
                <div className={styles.buttons}>
                    <button onClick={addMethod}>Add Method</button>
                    <button style={{marginLeft:"30px"}} disabled={!isDirty()} onClick={saveChanges}>Save Changes</button>
                </div>
            </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>On-Call Protocol for <span className={styles.content}>{props.globals.practice.name}</span></div>
                <ShowProviders />
                <div className={styles.page}>
                    <div className={styles.header}>Timeline</div>
                    <div className={styles.header}>Contact Methods
                        {range.start.length>0 && <span style={{marginLeft:"8px"}}>for {range.start} to {range.end}</span>}
                    </div>
                    <div className={styles.header}>Wait Time (minutes)</div>
                    {ShowTimeline() }
                    {ShowSteps() }
                </div>
                {showdirty && <ShowDirty />}
            </div>
        </React.Fragment>
    )
}

export default OnCallProtocol;