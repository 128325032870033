// Constants used by everyone

// Set to true before building on Production server
const InProduction = false

const Local = process.env.NODE_ENV === 'development';

export var TEST_NOTICE = 'TESTING';

export var BASE_URL = '';
if (Local === true) {
    BASE_URL = 'http://localhost:8989/';
    TEST_NOTICE = 'LOCAL';
} else {
  if (InProduction) {
    BASE_URL = 'https://www.patient-source.com:8989/';
    TEST_NOTICE = '';
  } else {
    BASE_URL = 'https://ptstest.patient-source.com:8989/';
  }
    
}
export var API_URL = '';
if (Local === true) {
    API_URL = 'http://localhost:8989/';
} else {
    
    if (InProduction) {
      API_URL = 'https://www.patient-source.com:8989/';
    } else {
      API_URL = 'https://ptstest.patient-source.com:8989/';
    }
}
export var WS_URL = '';
if (Local === true) {
    WS_URL = 'ws://localhost:8989/';
} else {
    
    if (InProduction) {
      WS_URL = 'ws://www.patient-source.com:8989/';
    } else {
      WS_URL = 'ws://ptstest.patient-source.com:8989/';
    }
}

export const APP_VERSION = 1;
export const FRAMEWORK_EXTERNAL = 0;
export const FRAMEWORK_INTERNAL = 1;

export const ACC_TYPE_AFTER_HOURS = 1;
export const ACC_TYPE_PROVIDER = 2;
export const ACC_TYPE_OVERFLOW = 3;
export const RELATIONSHIP_SELF = 6;
export const RELATIONSHIPS = {0:"Daughter",1:"Doctor",2:"Father",3:"Mother",4:"Not Related",5:"Other Family",6:"Self",7:"Son",8:"Spouse",9:"Unknown"};
export const APPTREASONS = {0:"Requesting New Appointment",1:"Confirming Scheduled Appointment",2:"Cancelling Scheduled Appointment - would like to reschedule",3:"Cancelling Scheduled Appointment - do not intend to reschedule", 4:"Uncertain of Scheduled Appointment - need callback",5:""};
export const ACTIONS = {1:'Create',2:'Page On-Call',3:'Acknowledge',4:'Transfer Internally',5:'Transfer to IC',6:'Add Notes',7:'Close',8:'Re-open',9:'Update',10:'On-Call Call Success',13:'Notify Caller',12:'Call - Failed',14:'Transfer to Practice',15:'Patch Caller',16:'Page Office',17:'Deliver Message',20:'Office Call Failed',21:'Edit',34:'Protocol Dispatched',22:'Pull Chart', 23:'Verify',24:'Sign',25:'Fax',26:'Phone',27:'Print',28:'Uncheck Pull Chart',29:'Uncheck Verify',30:'Uncheck Sign',31:'Fax - Successfull',32:'Fax - Failed',33:'Restart Protocol'};
export const ACTIONS_TAKEN = {1:'Created',2:'On-Call Paged',3:'Acknowledged',4:'Transferred',5:'Transferred to IC',6:'Notes Added',7:'Closed',8:'Re-opened',9:'Updated',10:'On-Call Contacted',13:'Caller Notified',12:'Unable to Contact On-Call',14:'Transferred from IC',15:'Caller Patched',16:'Office Paged',17:'Message Delivered',20:'Unable to Contact Office',21:'Edited',34:'Protocol Dispatched',22:'Chart Pulled',23:'Verified',24:'Signed',25:'Fax Scheduled to be Sent',26:'Phoned In',27:'Printed',28:'Pull Chart Unchecked',29:'Verify Unchecked',30:'Sign Unchecked',31:'Fax Sent Successfully',32:'Fax Not Sent',33:'Restart On-call Contact Protocol'};
export const ACTIONADDNOTE = 6;
export const ADDNOTEREASONS = {
    1:"Follow-up needed",
    2:"Issue identified",
    3:"Issue resolved",
    5:"Reporting progress",
    6:"Unable to contact caller"
};
export const ACTIONREASONS = {
  1:"Follow-up needed",
  2:"Issue identified",
  3:"Issue resolved",
  5:"Reporting progress"  
}
export const PAGERTYPES = {1:"Digitial",2:"Alpha"};
export const PTAUTHTYPES = {
  0:"None",
  1:"Appointment Only",
  2:"Rx Only",
  3:"Both Appointment & Rx",
  4:"Direct Access, Appointment Only",
  5:"Direct Access, Rx Only",
  6:"Direct Access, Both Appointment & Rx"
};
export const CALLTYPES = {
  0:"",
  1:"Voice",
  2:"Fax",
  3:"Pager",
  4:"Home Phone",
  5:"Email",
  6:"Cell Phone"
};

//Keep in sync with WorkFlowStates table!!!
export const STATEID_CLOSED = 12;
export const STATEID_QUEUE = 2;

export const SORTBY_MSGID = 8;

export const CALLTYPE_URGENT = 1;
export const CALLTYPE_TRANSFER_CALL = 2;
export const CALLTYPE_NON_URGENT = 3;
export const CALLTYPE_APPT_CANCEL = 4;
export const CALLTYPE_CALLBACK = 5;
export const CALLTYPE_APPT_INQUIRY = 6;
export const CALLTYPE_APPT_REQUEST = 7;

export const MSGTYPE_APPT = 2;
export const MSGTYPE_RX = 3;
export const MSGTYPE_BILLING = 4;
export const MSGTYPE_CLINICAL = 5;
export const MSGTYPE_MEDRECORDS = 6;
export const MSGTYPE_NONCLINICAL = 7;
export const MSGTYPE_TESTS = 9;
export const MSGTYPE_INFO = 10;
export const MSGTYPE_REFERRAL = 11;
export const MSGTYPE_INCOMPLETE = 12;
export const MSGTYPE_ADULTMED = 13;
export const MSGTYPE_OBGYN = 14;
export const MSGTYPE_PEDIATRICS = 15;
export const MSGTYPE_APPTREQUEST = 16;
export const MSGTYPE_APPTCANCEL = 17;
export const MSGTYPE_APPTINQUIRY = 18;
export const MSGTYPE_PREAUTH = 19;
export const MSGTYPE_APPTRESCHEDULE = 20;

export const MSGTYPE_HANDLED_INTERNAL = 1;
export const MSGTYPE_HANDLED_EXTERNAL = 2;

export const INFOTYPE_DOB = 4;
export const INFOTYPE_CALLERNAME = 5;
export const INFOTYPE_RELATIONSHIP = 6;
export const INFOTYPE_DAYPHONE1 = 8;
export const INFOTYPE_DAYPHONE2 = 9;
export const INFOTYPE_EVEPHONE1 = 10;
export const INFOTYPE_EVEPHONE2 = 11;

export const WORKFLOW_ACTION_CREATED = 1;
export const WORKFLOW_ACTION_DOSTEP = 2;
export const WORKFLOW_ACTION_WAITING = 3;
export const WORKFLOW_ACTION_RESTART = 4;
export const WORKFLOW_ACTION_CLOSE = 5;

export const PRIORITY_NO_ACTION = 0;
export const PRIORITY_LOW = 1;
export const PRIORITY_MEDIUM = 2;
export const PRIORITY_HIGH = 3;
export const PRIORITY_OMG = 4;
//export const PRIORITY_HOLDING = 5;

//export const PRIORITIES = ['No Action','Low','Medium','High','OMG','Holding'];
export const PRIORITIES = ['No Action','Low','Medium','High','OMG','OMG+1','OMG+2','OMG+3'];

export function ArgsToParms(args) {
    var k,v;
    var parms = '';
    if (args != null) {
        for (k in args) {
            v = args[k];
            parms += '&'+k+'='+v;
        }
    }

    return parms;
}

export function PhoneTest(phonenum,shortver=true) {
	var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  var sa = phonenum.split('x');
  var pext = '';
  var s = '';
  if (sa.length > 1) pext = sa[1].trim();
  
  phonenum = sa[0].trim();
	if (phoneRegex.test(phonenum)) {
    	var formattedPhoneNumber = phonenum.replace(phoneRegex, "($1) $2-$3");
      s = formattedPhoneNumber;
      if (shortver) {
      	s = s.replace('(','').replace(')','').replace('-','').replace(' ','');
      }
      if (pext.length) {
        s += ' x'+pext;
      }
      
	} else {
    // Invalid phone number
    s = "";
	}
  return s;
}

export function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;
  let rvalue = '';
  let sa = value.split('x');
  let ext = '';
  value = sa[0];
  if (sa.length > 1) {
    ext = sa[1];
    if (ext.length) {
      ext = 'x'+ext;
    }
  }
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) {
    rvalue = phoneNumber;
    return rvalue.trimEnd();
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    rvalue = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)} ${ext}`;
    return rvalue.trimEnd();
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  rvalue = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)} ${ext}`;
  return rvalue.trimEnd();
}

export function generateKey(pre) {
    return `${ pre }_${ new Date().getTime() }`;
}

export function formatAllPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
// import {Date24, Date12, DateOnly, Time24, CompareDates, EqualHourMin, CompareSameDayTimes, DateBetween, DateOverlap, FmtTime, DateToMin, FmtDateTime, HourMinToDate}

  export function CleanDate(dt) {
    dt = "" + dt;
    dt = dt.replace(/\\/g,"/");
    dt = dt.split(' ')[0];
    let sep = '';
    let mn = '';
    let dy = '';
    let yr = '';
    if (dt.indexOf('/') >= 0) sep = '/';
    if (dt.indexOf('-') >= 0) sep = '-';
    if (dt.indexOf('.') >= 0) sep = '.';
    
    if (sep.length) {
      let sa = dt.split(sep);
      mn = sa[0];
      //Check if its yyyy-mm-dd instead of mm-dd-yyyy
      if (mn.length===4) {
        yr = mn;
        if (sa.length > 1) mn = sa[1];
        if (sa.length > 2) dy = sa[2];
      } else {
        if (sa.length > 1) dy = sa[1];
        if (sa.length > 2) yr = sa[2];  
      }
    } else {
      if (dt.length !== 8) {
        return "ERROR 1";
      }
      mn = dt.slice(0,2);
      dy = dt.slice(2,4);
      yr = dt.slice(4);
    }
    yr = "" + yr;
    if (yr.length < 4) {
      return "ERROR 2";
    }
    mn = "" + mn;
    dy = "" + dy;
    if (mn.length > 0 && mn.length < 2) mn = "0" + mn;
    if (dy.length > 0 && dy.length < 2) dy = "0" + dy;
    dt = mn + "/" + dy + "/" + yr;
    if (dt.length !== 10) {
      return "ERROR 3";
    }
    if (parseInt(mn) > 12) {
      return "ERROR 4";
    }
    let v= new Date(dt);
    let options={year:"numeric",month:"2-digit",day:"2-digit"};
    dt = v.toLocaleDateString("en-us",options);
    if (dt==='Invalid Date') {
      dt = "ERROR 5";
    }
    return dt;
}

  export function Date24(dt) {
      if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
      const options={month:"2-digit",day:"2-digit",year:"numeric",hour:"2-digit",minute:"2-digit",second:"2-digit",hour12:false}
      return new Date(dt).toLocaleDateString("en-us",options).replace(",","").replace(" 24:"," 00:");
  }

  export function Date12(dt) {
    if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
    const opts = {month:"short",day:"numeric",year:"numeric",hour:"numeric",minute:"numeric",hour12:true};
    return new Date(dt).toLocaleDateString("en-us",opts).replace(",","");
  }

  export function DateOnly(dt) {
    if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
    const options={year:"numeric",month:"2-digit",day:"2-digit"};
      return new Date(dt).toLocaleDateString("en-us",options);
  }

  export function Time24(dt) {
    if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
    const options={hour:"2-digit",minute:"2-digit",hour12:false};
      return new Date(dt).toLocaleTimeString("en-us",options).replace("24:","00:");
  }

  export function calculateAgeInYears (birthdate) {
    var now = new Date();
    var date = new Date(birthdate);
    var current_year = now.getFullYear();
    var year_diff = current_year - date.getFullYear();
    var birthday_this_year = new Date(current_year, date.getMonth(), date.getDate());
    var has_had_birthday_this_year = (now >= birthday_this_year);

    return has_had_birthday_this_year
        ? year_diff
        : year_diff - 1;
  }

  export function CompareDates(dt1,dt2) {
    if (typeof dt1 === "string") dt1 = dt1.replace(" 24:"," 00:");
    if (typeof dt2 === "string") dt2 = dt2.replace(" 24:"," 00:");
    var d1 = new Date(dt1)
    var d2 = new Date(dt2)
    d1.setMilliseconds(0);
    d2.setMilliseconds(0);
    if (d1 < d2) return -1;
    if (d1 > d2) return 1;
    return 0;      
  }

  export function EqualHourMin(dt1,dt2) {
    if (typeof dt1 === "string") dt1 = dt1.replace(" 24:"," 00:");
    if (typeof dt2 === "string") dt2 = dt2.replace(" 24:"," 00:");
    var d1 = new Date(dt1)
    var d2 = new Date(dt2)
    if (d1.getMonth()===d2.getMonth() &&
        d1.getDate()===d2.getDate() &&
        d1.getFullYear()===d2.getFullYear() &&
        d1.getHours()===d2.getHours() &&
        d1.getMinutes()===d2.getMinutes()) {
            return true;
        }
    return false;
  }

  export function CompareSameDayTimes(dt1,dt2) {
    if (typeof dt1 === "string") dt1 = dt1.replace(" 24:"," 00:");
    if (typeof dt2 === "string") dt2 = dt2.replace(" 24:"," 00:");
    var d1 = new Date(dt1)
    var d2 = new Date(dt2)
    d1.setMilliseconds(0);
    d1.setSeconds(0);
    d2.setMilliseconds(0);
    d2.setSeconds(0);
    if (d1.getMonth()===d2.getMonth() &&
        d1.getDate()===d2.getDate() &&
        d1.getFullYear()===d2.getFullYear()) {
            if (d1.valueOf()===d2.valueOf()) return 0;
            if (d1.valueOf() < d2.valueOf()) return -1;
            if (d1.valueOf() > d2.valueOf()) return 1;
        }
    return -2;        
  }

  // 0 = In between low and high
  // -1 = less than low
  // 1 = greater than high
  // -2 = undefined
  export function DateBetween(dtcheck,dtlow,dthigh) {
    if (typeof dtcheck === "string") dtcheck = dtcheck.replace(" 24:"," 00:");
    if (typeof dtlow === "string") dtlow = dtlow.replace(" 24:"," 00:");
    if (typeof dthigh === "string") dthigh = dthigh.replace(" 24:"," 00:");

      var dtc = new Date(dtcheck);
      var dtl = new Date(dtlow);
      var dth = new Date(dthigh);
        dtc.setMilliseconds(0);
        dtl.setMilliseconds(0);
        dth.setMilliseconds(0);
        if (dtc >= dtl && dtc < dth) return 0;
        if (dtc < dtl) return -1;
        if (dtc > dth) return 1;
        return -2;
  }

  // 0 = start before low and end after low
  // -1 = start after low and end before high
  // 1 = start after low and end after high
  // 2 = start after high
  // -2 = undefined
  export function DatesOverlap(dtstart,dtend,dtlow,dthigh) {
    if (typeof dtstart === "string") dtstart = dtstart.replace(" 24:"," 00:");
    if (typeof dtend === "string") dtend = dtend.replace(" 24:"," 00:");
    if (typeof dtlow === "string") dtlow = dtlow.replace(" 24:"," 00:");
    if (typeof dthigh === "string") dthigh = dthigh.replace(" 24:"," 00:");
      var dts = new Date(dtstart);
      var dte = new Date(dtend);
      var dtl = new Date(dtlow);
      var dth = new Date.UTC(dthigh);
      dts.setMilliseconds(0);
      dte.setMilliseconds(0);
      dtl.setMilliseconds(0);
      dth.setMilliseconds(0);
      if (dts < dtl && dte > dtl) return 0;
      if (dts >= dtl && dte < dth) return -1;
      if (dts >= dtl && dte >= dth) return 1;
      if (dts > dth) return 2;
      return -2;
  }

export const FmtTime = (dt) => {
    if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
    return new Date(dt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

export const DateToMin = (dtmin) => {
    if (typeof dtmin === "string") dtmin = dtmin.replace(" 24:"," 00:");
    var dt = new Date(dtmin);
    return dt.getHours() * 60 + dt.getMinutes();
}

export const FmtDateTime = (dt) => {
    if (typeof dt === "string") dt = dt.replace(" 24:"," 00:");
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const fd = new Date(dt);
    return fd.toLocaleDateString("en-us",options);
}

export const HourMinToDate = (dtuse, hm,ampm) => {
    if (typeof dtuse === "string") dtuse = dtuse.replace(" 24:"," 00:");
    console.log("HMTD hm='"+hm+"'");
    let dt = new Date(dtuse);
    const sa = hm.trim().split(':');
    if (sa.length > 1) {
        let hour = parseInt(sa[0]);
        const sb = sa[1].split(' ');
        let minute = parseInt(sb[0]);
        if (hour===12) hour=0;
        if (ampm) hour += 12;
        dt.setHours(hour);
        dt.setMinutes(minute);
        dt.setSeconds(0);
    }
    return dt;
}

export const BuildName = (first, last, middle) => {
    let name = "";
    if (first.length) name = first + " ";
    if (middle.length) name += middle + " ";
    name += last;
    return name;
}
