import React from "react";
import styles from "./TakeNewMessage.module.scss";
import {useNavigate} from 'react-router-dom'; 
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenTertiary from "../../components/GenTertiary";
import ContactScreen from "./ContactScreen";
import ContactPopup from "./ContactPopup";
import ApptCancel from "../../components/ApptCancel";
import ApptRequest from "../../components/ApptRequest";
import ApptInquiry from "../../components/ApptInquiry";
import MsgOther from "../../components/MsgOther";
import MessageCommon from "../../components/MessageCommon";
import PracticeInfo from "../../components/PracticeInfo";
import MessageExtraInfo from "../../components/MessageExtraInfo";
import SearchTickets from "./SearchTickets";
import { calculateAgeInYears, CleanDate, PhoneTest, ACC_TYPE_OVERFLOW } from "./../../AppConstants";
import {MSGTYPE_APPT, MSGTYPE_NONCLINICAL, MSGTYPE_APPTCANCEL, MSGTYPE_APPTREQUEST, MSGTYPE_APPTINQUIRY, MSGTYPE_CLINICAL, MSGTYPE_INFO, MSGTYPE_INCOMPLETE} from "./../../AppConstants";
import {CALLTYPE_URGENT, CALLTYPE_TRANSFER_CALL, CALLTYPE_NON_URGENT, CALLTYPE_APPT_CANCEL, CALLTYPE_CALLBACK, CALLTYPE_APPT_INQUIRY, CALLTYPE_APPT_REQUEST} from "./../../AppConstants";
import {MSGTYPE_HANDLED_INTERNAL, MSGTYPE_HANDLED_EXTERNAL, RELATIONSHIP_SELF} from "./../../AppConstants";

var CREATED_MSGID = 0;

const TakeNewMessage = (props) => {
    const [shownewconfirm,setShowNewConfirm] = React.useState(false);
    const [showcancelconfirm,setShowCancelConfirm] = React.useState(false);
    const [showcontact,setShowContact] = React.useState(false);
    const [showalert,setShowAlert] = React.useState(false);
    const [showholding,setShowHolding] = React.useState(false);
    const [alertdata,setAlertData] = React.useState({});
    const [showsearch,setShowSearch] = React.useState(false);
    const [inputs,setInputs] = React.useState(getNewObj());

    const [refdata,setRefData] = React.useState({
        msgfields:[],
        extrainfo:[],
        provlist:[],
        greets:[],
        callnotes:[],
        locations:[],
        msgtypes:[]
    });

    let bDOB = false;
    let bDayPhone1 = false;

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.takemessageinit(props.globals.practiceid).then((data)=> {
            setRefData(data);
            setInputs(getNewObj());
        })
    },[props.globals.sessionguid,props.globals.practiceid])

    const mtype = parseInt(inputs.msgtype || '0');
    refdata.msgfields.forEach((mf)=> {
        if ((mf.msgtype===0 || mf.msgtype===mtype) && mf.ask==='1') {
            switch(mf.infotype) {
                case 4:
                    bDOB = true;
                    break;
                case 8:
                    bDayPhone1 = true;
                    /*if (mtype === MSGTYPE_CLINICAL)
                        bDayPhone1 = true;
                    */
                    break;
            }
        }
    })

    const requiredFields = {
        [CALLTYPE_APPT_CANCEL]:['ptfirst','ptlast','ptdob','apptdate','apptdesc','msgbody','dyphone1','cancel'],
        [CALLTYPE_APPT_INQUIRY]:['ptfirst','ptlast','ptdob','msgbody','dyphone1'],
        [CALLTYPE_APPT_REQUEST]:['ptfirst','ptlast','ptdob','apptdesc','msgbody','dyphone1'],
        [CALLTYPE_NON_URGENT]:['ptfirst','ptlast','ptdob','msgbody','dyphone1'],
        [CALLTYPE_TRANSFER_CALL]:['ptfirst','ptlast','ptdob','dyphone1'],
        [CALLTYPE_URGENT]:['ptfirst','ptlast','ptdob','msgbody','dyphone1']
    }

    const requiredText = {
        ptfirst:"Patient First Name",
        ptlast:"Patient Last Name",
        ptdob:"Patient Date of Birth",
        apptdate:"Appointment Date",
        apptdesc:"Appointment Description",
        msgbody:"Message Text",
        dyphone1:"Callback Phone",
        cancel:"Cancelling / Confirming"
    }

    function generateGUIDWithCrypto() {
        // Check if crypto is available
        if (typeof crypto === 'undefined' || !crypto.getRandomValues) {
          throw new Error('Your browser does not support crypto.getRandomValues()');
        }
      
        // Create an array to store the random values
        const buffer = new Uint8Array(16);
      
        // Fill the array with random values
        crypto.getRandomValues(buffer);
      
        // Set the version (4) and variant bits
        buffer[6] = (buffer[6] & 0x0f) | 0x40;  // Version 4
        buffer[8] = (buffer[8] & 0x3f) | 0x80;  // Variant 1
      
        // Convert the array to a hexadecimal string
        const hexValues = Array.from(buffer, byte => byte.toString(16).padStart(2, '0'));
      
        // Format the hexadecimal string as a GUID
        
        
        return [
          hexValues.slice(0, 4).join(''),
          hexValues.slice(4, 6).join(''),
          hexValues.slice(6, 8).join(''),
          hexValues.slice(8, 10).join(''),
          hexValues.slice(10, 16).join('')
        ].join(''); //.join('-');
        
      }
      

    function getNewObj() {
        var obj = {
            callerfirst:"",
            callerlast:"",
            relationship:-1,
            patientfirst:"",
            patientlast:"",
            dob:"",
            provider:0,
            msgtype:"0",
            calltype:0,
            intext:MSGTYPE_HANDLED_EXTERNAL,
            message:"",
            location:"0",
            movelocation:"0",
            apptreason:"0",
            apptdate:"",
            moveapptdate:"",
            ckmon:0,
            cktue:0,
            ckwed:0,
            ckthu:0,
            ckfri:0,
            cksat:0,
            cksun:0,
            ckam:0,
            ckpm:0,
            dayphone1:"",
            dayext1:"",
            dayphone2:"",
            dayext2:"",
            eveningphone1:"",
            eveningext1:"",
            eveningphone2:"",
            eveningext2:"",
            okleavemsg:"N",
            email:"",
            msgid:0,
            numberonfile:"",
            nofext:"",
            newpatient:"N",
            callbackphone:"",
            cbpext:"",
            apptdesc:"",
            appttime:"",
            moveapptdesc:"",
            moveappttime:"",
            cancel:'',
            reschedule:'N',
            priority:1
        }
        return obj;
    }


    const HandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let obj = {[name]:value}
        setInputs(values => ({...values, ...obj}));
    }

    const HandleCallType = (event) => {
        let calltypes = refdata.calltypes || [];
        const name = event.target.name;
        const value = event.target.value;
        let obj = {[name]:value}
        let mtype = calltypes.find((mt => ''+mt.calltypeid===value));
        if (mtype) {
            obj['msgtype'] = mtype.messagetypeid;
            obj['intext'] = mtype.intext;
            obj['apptdate'] = '';
            obj['appttime'] = '';
        } else {
            obj.msgtype = 0;
            obj.intext = 0;
        }
        setInputs(values => ({...values, ...obj}));
    }

    const cbChange = (nm,v) => {
        let obj = {[nm]:v}
        setInputs(values => ({...values, ...obj}));
    }

    const history = useNavigate();
    const doWait = (v) => {
        let bError = doContact(false);
        if (!bError) return;
        let pobj = {practiceid:0,practiceaccount:{},practice:{name:""}}
        props.methods.SetObject(pobj);
        history("/");
    }
    const doSubmitYes = () => {
        setShowHolding(false);
        let bError = doContact(false,false,false,1);
        if (!bError) return;
        let pobj = {practiceid:0,practiceaccount:{},practice:{name:""}}
        props.methods.SetObject(pobj);
        history("/");
    }
    const doSubmitNo = () => {
        setShowHolding(false);
        let bError = doContact(false);
        if (!bError) return;
        let pobj = {practiceid:0,practiceaccount:{},practice:{name:""}}
        props.methods.SetObject(pobj);
        history("/");
    }
    const doSubmitCancel = () => {
        setShowHolding(false);
    }

    const doSubmit = (v) => {
        const mtype = '' + inputs.msgtype;
        const imtype = parseInt(mtype);
        let holding = (mtype===''+MSGTYPE_APPTREQUEST || mtype===''+MSGTYPE_APPTINQUIRY);
        if (mtype==='17' && inputs.reschedule==='Y') holding = true;
        let handled = 0;
        let mt = refdata.msgtypes.find(obj => obj.msgtype === imtype);
        if (mt) {
            handled = mt.handled;
        }
        
        if (handled===2) holding = false; // Don't prompt for Holding if practice doesn't want internal to handle it

        if (!holding) {
            doSubmitNo();
        } else {
            let bError = doContact(false,false,true);
            if (!bError) return;
            setShowHolding(true);
        }

    }

    const doContact = (bContinue,bReturn=false,bValidateOnly=false,holding=0) => {
        let obj = {};
        let errs = [];
        let errfields = [];
        const patype = props.globals.practiceaccount.accounttype;
        let ack = "Y";
        obj.msgid = inputs.msgid;

        let msghandling = 'S';
        if (bContinue) {
            if (patype === ACC_TYPE_OVERFLOW) {
                msghandling = 'O';
            } else {
                msghandling = 'U';
                ack = "N";
            }
        }
        let intext = MSGTYPE_HANDLED_EXTERNAL;
        const mtid = ''+inputs.msgtype;
        refdata.calltypes.every((ct)=> {
            if (''+ct.messagetypeid===mtid) {
                intext = parseInt(ct.intext);
                return false;
            }
            return true;
        })
        obj.ack = ack;
        let s = "";
        let status = 1;
        let mtype = parseInt(inputs.msgtype) || 0;
        if (mtype===MSGTYPE_INFO || mtype===MSGTYPE_INCOMPLETE || mtype===0) {
            status = 0;
        }
        obj.status = status;
        obj.msghandling = msghandling;
        obj.nowphone = ""; // Apparently not used, always null
        obj.okleavemsg = "N"; // Always null or N
        obj.calleremail = ""; // Always null
        obj.callerorg = "";
        obj.cbpersonid = 0;
        obj.cnfirst = inputs.callerfirst;
        obj.cnlast = inputs.callerlast;
        obj.relationship = inputs.relationship;
        if (obj.relationship < 0) obj.relationship = RELATIONSHIP_SELF; // Default to "Not Related"
        obj.ptfirst = inputs.patientfirst;
        obj.ptlast = inputs.patientlast;
        if (''+obj.relationship===''+RELATIONSHIP_SELF) {
            if (obj.cnfirst.length === 0 && obj.cnlast.length === 0) {
                obj.cnfirst = obj.ptfirst;
                obj.cnlast = obj.ptlast;
            }
        }
        obj.ptdob = inputs.dob;
        
        if (obj.ptdob.length) {
            s = obj.ptdob;
            if (s.length > 0 && s.toLowerCase() !== 'unknown') {
                s = CleanDate(obj.ptdob);
                if (s.indexOf("ERROR")===0) {
                    errs.push("Invalid DOB");
                    errfields.push("dob");
                } else {
                    obj.ptdob = s;
                    if (calculateAgeInYears(s) < 0) {
                        errs.push("Cannot have DOB in future!");
                        errfields.push("dob");
                    }
                }
            }
        }
        obj.msgbody = inputs.message;
        
        obj.uaid = props.globals.useraccountid;
        obj.userpracticeid = props.globals.userpracticeid;
        obj.providerid = inputs.provider;

        obj.practiceid = props.globals.practiceid;
        obj.msgtype = inputs.msgtype;
        obj.locid = inputs.location;
        obj.movelocid = inputs.movelocation;
        obj.apptreason = inputs.apptreason || 0;
        obj.apptdate = inputs.apptdate || "";
        if (obj.apptdate.length) {
            s = CleanDate(obj.apptdate);
            if (s.indexOf("ERROR")===0) {
                errs.push("Invalid Appt Date");
                errfields.push("apptdate");
            } else {
                obj.apptdate = s;
            }
        } 
        obj.moveapptdate = inputs.moveapptdate || "";
        if (obj.moveapptdate.length) {
            s = CleanDate(obj.moveapptdate);
            if (s.indexOf("ERROR")===0) {
                errs.push("Invalid Reschedule Appt Date");
                errfields.push("moveapptdate");
            } else {
                obj.moveapptdate = s;
            }
        }
        obj.dowpref = inputs.ckmon | inputs.cktue | inputs.ckwed | inputs.ckthu | inputs.ckfri | inputs.cksat | inputs.cksun;
        obj.todpref = inputs.ckam | inputs.ckpm;
        let pn = inputs.dayphone1;
        s = '';
        obj.dyphone1 = s;
        if (pn.length) {
            if (inputs.dayext1.length) pn += ' x' + inputs.dayext1;
            s = PhoneTest(pn,true);
            if (!s.length) {
                errs.push('Invalid Callback Phone');
                errfields.push("dayphone1");
            } else {
                obj.dyphone1 = s;
            }
        } /* else {
            if (bDayPhone1 === true && intext === MSGTYPE_HANDLED_EXTERNAL) {
                errs.push('Callback Phone cannot be blank');
                errfields.push("dayphone1");
            }
        } */
        s = '';
        obj.dyphone2 = s;
        pn = inputs.dayphone2;
        if (pn.length) {
            if (inputs.dayext2.length) pn += ' x' + inputs.dayext2;
            s = PhoneTest(pn,true);
            if (!s.length) {
                errs.push('Invalid Day Phone 2');
                errfields.push("dayphone2");
            } else {
                obj.dyphone2 = s;
            }
        }
        s = '';
        obj.evephone1 = s;
        pn = inputs.eveningphone1;
        if (pn.length>0) {
            if (inputs.eveningext1.length) pn += ' x'+inputs.eveningext1;
            s = PhoneTest(pn,true);
            if (!s.length) {
                errs.push('Invalid Evening Phone 1');
                errfields.push("evephone1");
            } else {
                obj.evephone1 = s;
            }
        }
        s = '';
        obj.evephone2 = s;
        pn = inputs.eveningphone2;
        if (pn.length>0) {
            if (inputs.eveningext2.length) pn += ' x'+inputs.eveningext2;
            s = PhoneTest(pn,true);
            if (!s.length) {
                errs.push('Invalid Evening Phone 2');
                errfields.push("evephone2");
            } else {
                obj.evephone2 = s;
            }
        }
        s = '';
        obj.numberonfile = s;
        pn = inputs.numberonfile;
        if (pn.length>0) {
            if (inputs.nofext.length) pn += ' x'+inputs.nofext;
            s = PhoneTest(pn,true);
            if (!s.length) {
                errs.push('Invalid Number on file');
                errfields.push("numberonfile");
            } else {
                obj.numberonfile = s;
            }
        }
        s = '';
        obj.callbackphone = s;
        pn = inputs.callbackphone;
        if (pn.length>0) {
            if (inputs.cbpext.length) pn += ' x'+inputs.cbpext;
            s = PhoneTest(pn,true);
            if (!s.length) {
                errs.push('Invalid Callback phone');
                errfields.push("callbackphone");
            } else {
                obj.callbackphone = s;
            }
        }
        obj.apptdesc = inputs.apptdesc;
        obj.appttime = inputs.appttime;
        obj.moveapptdesc = inputs.moveapptdesc || "";
        obj.moveappttime = inputs.moveappttime || "";

        obj.reschedule = inputs.reschedule;
        obj.newpatient = inputs.newpatient;
        obj.cancel = inputs.cancel;
        obj.priority = inputs.priority;
        obj.holding = holding;
        
        let ei = [];
        refdata.extrainfo.forEach((n,i) => {
            const nm = "ei_"+n.infotype;
            let eobj = {}
            eobj.infotype = n.infotype;
            eobj.msgtype = n.msgtype;
            eobj.value = inputs[nm] || "";
            ei.push(eobj);
        })
        obj.extras = ei;

        
        if (''+obj.msgtype==='0') {
            errs.push('Invalid Message Type');
            errfields.push('msgtype');
        }
        let ct = 0;
        if (calltype.length > 0) {
            ct = parseInt(calltype);
        }
        const flds = requiredFields[ct] || [];
        flds.forEach((fld)=> {
            if (obj[fld].length < 1) {
                if (fld==='dyphone1' && bDayPhone1===false) return;
                if (fld==='ptdob' && bDOB===false) return;
                const etxt = requiredText[fld] || "";
                if (etxt.length) {
                    errs.push(etxt+" cannot be blank.");
                }
            }
        })
        
        if (obj.ptdob.toLowerCase()==='unknown') obj.ptdob = '';

        if (errs.length > 0) {
            // show errors here
            alert(errs.join('\n'));
            return false;
        }

        if (bValidateOnly) return true;

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savemessage(obj).then((data) => {
            let msgid = data.MSGID || 0;
            let tkid = data.TICKETID || 0;
            let errs = data.ERROR || "";
            let result = data.RESULT || "ok";
            if (result !== "ok") {
                if (errs.length > 0) {
                    alert(errs);
                    return !bContinue;
                }
            }
            CREATED_MSGID = msgid;
            //const obj = getNewObj();
            if (holding > 0) {
                if (tkid > 0) {
                    navigator.clipboard.writeText(''+tkid).then(() => {
                        alert('Ticket # '+tkid+' was copied to clipboard. Please Paste it into Phone System.');
                    })
                    .catch((err) => {
                        console.error('Failed to copy text: ', err);
                        alert('Failed to copy text. Please to Copy/Paste the ticket #'+tkid);
                    });
                    //alert("Copy/Paste the following Ticket ID to phone system:   "+tkid);
                } else {
                    alert("NO Ticket ID was created. Contact System Administrator.");
                }
            }
            const obj = {};
            setInputs(values => ({...values, ...obj}));
            //setInputs(values => ({...values, "msgid":msgid}))
            if (bContinue) {
                setShowContact(true);
            } else {
                if (!bReturn) {
                    let pobj = {practiceid:0,practiceaccount:{},practice:{name:""}}
                    props.methods.SetObject(pobj);        
                    history("/");
                }
            }
        })

        return true;
    }

    const setNewMsg = () => {
        //var obj = getNewObj();
        var obj = {};
        refdata.extrainfo.forEach((n,i) => {
            const nm = "ei_"+n.infotype;
            obj[nm] = "";
        })
        setInputs(values => ({...values, ...obj}));
    }

    const doCancel = (v) => {

    }


    const onNewYes = () => {
        doContact(false,true);
        setNewMsg();
        setShowNewConfirm(false);
    }

    const onNewNo = () => {
        setNewMsg();
        setShowNewConfirm(false);
    }

    const onNewCancel = () => {
        setShowNewConfirm(false);
    }
   
    const onCancelConfirm = () => {
        setShowCancelConfirm(false);
        history("/");
    }

    const onCancelCancel = () => {
        setShowCancelConfirm(false);
    }

    const onAlertClose = () => {
        setShowAlert(false);
        let pobj = {practiceid:0,practiceaccount:{},practice:{name:""}}
        props.methods.SetObject(pobj);
        history("/");
    }

    const updatePopup = (e) => {
        setShowAlert(false);
        if (props.globals.intext === 1) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.readalert(CREATED_MSGID).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                setAlertData(data.QDATA);
                setShowAlert(true);
            })
        }

    }

    const onContactClose = () => {
        setShowContact(false);
        let pobj = {practiceid:0,practiceaccount:{},practice:{name:""}}
        props.methods.SetObject(pobj);
        history("/");
    }

    const updateAlert = (e) => {
        setShowContact(false);
        if (props.globals.intext === 1) {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            dataservice.readalert(CREATED_MSGID).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                setAlertData(data.QDATA);
                setShowAlert(true);
            })
        }
    }

    const ShowButtons = () => {
        const mtype = '' + inputs.msgtype;
        const imtype = parseInt(mtype);
        if (mtype === '0') {
            return (
                <React.Fragment>
                    <div style={{display:"flex",marginTop:"80px"}}>
                    <div style={{marginLeft:"120px"}}><GenButton id="btncancel" buttontext="Cancel Call" width="116" onclick={(e)=>setShowCancelConfirm(true)} /></div>
                    </div>
                </React.Fragment>
            )
        }
        if (mtype===''+MSGTYPE_NONCLINICAL || mtype===''+MSGTYPE_APPTCANCEL || mtype===''+MSGTYPE_APPTREQUEST || mtype===''+MSGTYPE_APPTINQUIRY) {
            /*
            let holding = (mtype==='16' || mtype==='18');
            if (mtype==='17' && inputs.reschedule==='Y') holding = true;
            let handled = 0;
            let mt = refdata.msgtypes.find(obj => obj.msgtype === imtype);
            if (mt) {
                handled = mt.handled;
            }
            
            if (handled===2) holding = false; // Don't show holding button if practice doesn't want internal to handle it
            */
            return (
            <React.Fragment>
                <div style={{display:"flex",marginTop:"20px"}}>
                <div style={{marginLeft:"15px"}}><GenButton id="btnsubmit" buttontext="Submit" width="116" onclick={doSubmit} /></div>
                {/*{holding && <div style={{marginLeft:"15px"}}><GenButton id="btnhold" buttontext="Holding in Queue" width="116" onclick={doHolding} /></div>}*/}
                <div style={{marginLeft:"15px"}}><GenButton id="btncancel" buttontext="Cancel Call" width="116" onclick={(e)=>setShowCancelConfirm(true)} /></div>
                </div>
            </React.Fragment>
            )

        } else {
            return (
            <React.Fragment>
                <div style={{display:"flex",marginTop:"20px"}}>
                <div><GenButton id="btncontact" buttontext="Contact Now" width="116" onclick={(e)=>doContact(true)} /></div>
                {/*<div style={{marginLeft:"15px"}}><GenButton id="btnwait" buttontext="Wait for Next Call" width="116" onclick={doWait} /></div>*/}
                {/*<div style={{marginLeft:"15px"}}><GenButton id="btnnew" buttontext="New Message" width="116" onclick={(e)=>setShowNewConfirm(true)} /></div>*/}
                <div style={{marginLeft:"15px"}}><GenButton id="btncancel" buttontext="Cancel Call" width="116" onclick={(e)=>setShowCancelConfirm(true)} /></div>
                </div>
            </React.Fragment>
            )
        }
    }

    const sortpm = (a,b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    const SelectMessageType = () => {
        let msgtypes = refdata.msgtypes;
        let calltypes = refdata.calltypes || [];
        msgtypes.sort(sortpm);
        return (
            <React.Fragment>
                <div className={styles.formctrl}>
                    <div className={styles.formlabel}>Call Type:</div><div className={styles.req}>*</div>
                    <select className={styles.formselectreq} name="calltype" onChange={HandleCallType} value={inputs.calltype} >
                        <option value="0"></option>
                        {/*
                        {msgtypes.map((mt,i)=> {
                            if (mt.active==='Y') {
                                return <option value={""+mt.msgtype}>{mt.name}</option>
                            } else {
                                return null;
                            }
                        })}
                        */}
                        {calltypes.map((ct,i)=> {
                            if (ct.name === 'CallBack') return null;
                            return <option value={""+ct.calltypeid}>{ct.name}</option>
                        })}
                    </select>
                </div>
            </React.Fragment>
        )
    }

    const cancelSearch = () => {
        onCancelConfirm();
    }


    const rdata = refdata || {};

    const ShowTransferInst = () => {
        const transferinst = rdata.transferinst || [];
        const hasinst = transferinst.length;
        return (
            <div style={{marginTop:"10px",paddingRight:"10px",overflowWrap:"break-word"}}>
                {hasinst > 0 && <div className={styles.info}><b>Instructions:</b></div>}
                {transferinst.map((ti,i)=> {
                    return <div key={"ti"+i} className={styles.formnote} style={{paddingLeft:"10px",paddingRight:"10px"}} dangerouslySetInnerHTML={{ __html:ti.inst}} />
                })}
            </div>
        )
    }

    // TEST TEST TEST
    // alert("GUID="+generateGUIDWithCrypto());

    const calltype = '' + inputs.calltype || 0;
    const dphone1 = inputs.dayphone1 || "";
    if (calltype === ''+CALLTYPE_CALLBACK) {
        return (
            <React.Fragment>
                <div className={styles.main}>
                <div className={styles.title}>Callback for<span className={styles.content}>{props.globals.practice.name}</span></div>
                <div style={{marginLeft:"-144px"}}>{SelectMessageType()}</div>
                <hr/>
                <SearchTickets globals={props.globals} methods={props.methods} ischild={true} oncancel={cancelSearch} />
                </div>
            </React.Fragment>
        )
    }
    if (calltype === ''+CALLTYPE_TRANSFER_CALL) {
        return (
            <React.Fragment>
                <div className={styles.main}>
                    <div className={styles.title}>Practice Information for<span className={styles.content}>{props.globals.practice.name}</span></div>
                    <div className={styles.page}>
                    <div key="form_1" className={styles.form}>
                    {SelectMessageType()}
                    <MessageCommon 
                        globals={props.globals} 
                        methods={props.methods} 
                        refdata={rdata} 
                        mtype={inputs.msgtype} 
                        calltype={calltype} 
                        intext={inputs.intext} 
                        onChange={cbChange}
                        inputs={inputs}
                        showmsgblock={true} 
                    />
                    
                    <div style={{marginTop:"30px",marginLeft:"270px"}}><GenButton buttontext="End Call" onclick={onCancelConfirm} /></div>
                    <ShowTransferInst />
                    </div>
                    <PracticeInfo globals={props.globals} methods={props.methods} refdata={refdata} msgtype={inputs.msgtype} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <div className={styles.main}>
                <div className={styles.title}>Take Message for<span className={styles.content}>{props.globals.practice.name}</span></div>
                <div className={styles.page}>
                    <div key="form_1" className={styles.form}>
                        {SelectMessageType()}
                        {refdata.msgtypes.length > 0 && <MessageCommon 
                                                            globals={props.globals} 
                                                            methods={props.methods} 
                                                            refdata={rdata} 
                                                            mtype={inputs.msgtype} 
                                                            calltype={calltype} 
                                                            intext={inputs.intext} 
                                                            onChange={cbChange}
                                                            inputs={inputs}
                                                            showmsgblock={calltype==='0'} 
                                                        />}
                        {calltype===''+CALLTYPE_APPT_REQUEST && <ApptRequest locations={refdata.locations} inputs={inputs} refdata={rdata} mtype={inputs.msgtype} calltype={calltype} onChange={cbChange} askdesc={false} />}
                        {calltype===''+CALLTYPE_APPT_CANCEL && <ApptCancel locations={refdata.locations} inputs={inputs} refdata={rdata} mtype={inputs.msgtype} calltype={calltype} onChange={cbChange} askdesc={false} />}
                        {calltype===''+CALLTYPE_APPT_INQUIRY && <ApptInquiry locations={refdata.locations} inputs={inputs} refdata={rdata} mtype={inputs.msgtype} calltype={calltype} onChange={cbChange} askdesc={false} />}
                        {calltype===''+CALLTYPE_NON_URGENT && <MsgOther locations={refdata.locations} inputs={inputs} refdata={rdata} mtype={inputs.msgtype} calltype={calltype} onChange={cbChange} askdesc={true} />}
                        <ShowButtons />
                    </div>
                    <PracticeInfo globals={props.globals} methods={props.methods} refdata={refdata} msgtype={inputs.msgtype} />
                </div>
                
                {showholding && <GenTertiary 
                    globals={props.globals} 
                    methods={props.methods}
                    width="580"
                    text="Is caller Holding on-line?"
                    onCancel = {doSubmitCancel}
                    onYes = {doSubmitYes}
                    onNo = {doSubmitNo}
                    />}
                {shownewconfirm && <GenTertiary 
                    globals={props.globals} 
                    methods={props.methods}
                    width="580"
                    text="Do you want to save this message?"
                    onCancel = {onNewCancel}
                    onYes = {onNewYes}
                    onNo = {onNewNo}
                    />}
                {showcancelconfirm && <GenConfirm 
                    globals={props.globals} 
                    methods={props.methods}
                    width="400"
                    text="Cancel Message without saving?"
                    onCancel = {onCancelCancel}
                    onConfirm = {onCancelConfirm}
                    cancelText = "No"
                    confirmText = "Yes" />}
                {showcontact && <ContactScreen globals={props.globals} methods={props.methods} msgID={CREATED_MSGID} onUpdate={updateAlert} alertdata={alertdata} onClose={onContactClose} />}
                {showalert && <ContactPopup globals={props.globals} methods={props.methods} msgID={CREATED_MSGID} onUpdate={updatePopup} alertdata={alertdata} onClose={onAlertClose} />}
            </div>
        </React.Fragment>
    )
}

export default TakeNewMessage;