import React from "react";
import styles from "./User.module.scss";
import user from "../assets/user2.svg";

const User = () => {
  return (
    <div className={styles.user}>
      <img
        alt=""
        className={styles.icon}
        //src="https://static.overlay-tech.com/assets/e3c10ce2-17c5-4074-afca-de43d2ca1114.svg"
        src={user}
        width={20}
        
      />
    </div>
  );
};

export default User;