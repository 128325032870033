import React from "react";
import styles from "./LogOut.module.scss";
import exit from "../assets/exit.svg";

const LogOut = (props) => {
  return (
    <div className={styles.logOut}>
      <img
        alt=""
        className={styles.icon}
        style={{fill:"white !important"}}
        //src="https://static.overlay-tech.com/assets/ce38b1fa-6ca7-4732-8910-c57ca9f20dae.svg"
        src={exit}
        width={22}
        height={22}
      />
    </div>
  );
};

export default LogOut;