import React from "react";
import styles from "./TicketDashboard.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenDropdownCheck from "../../components/GenDropdownCheck";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITIES, Date12, calculateAgeInYears} from "../../AppConstants";

const TicketDashboard = (props) => {
    const [matrix,setMatrix] = React.useState({});
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [filterpracs,setFilterPracs] = React.useState([]);
    const [filterpriors,setFilterPriors] = React.useState([]);
    const [filtermsgtypes,setFilterMsgTypes] = React.useState([]);
    const [filterstatuses,setFilterStatuses] = React.useState([]);
    const [showtickets,setShowTickets] = React.useState(false);
    const [selectedtids,setSelectedTids] = React.useState('');
    const [tickets,setTickets] = React.useState([]);
    const [ticket,setTicket] = React.useState(null);
    const [showticket,setShowTicket] = React.useState(false);
    const [holding, setHolding] = React.useState(0);
    const [ipstate,setIPState] = React.useState(-1);
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [ticketsortfield,setTicketSortField] = React.useState('');
    const [ticketsortorder,setTicketSortOrder] = React.useState('');
    const [msgtext,setMsgText] = React.useState('');
    const [dirtythres,setDirtyThresholds] = React.useState(false);

    React.useEffect(()=> {
        console.log('Clearing Component States...');
        setMatrix({});
        setFilterPracs([]);
        setFilterPriors([]);
        setFilterStatuses([]);
        setShowTickets(false);
        setSelectedTids('');
        setTickets([]);
        setShowTicket(false);
        setTicketSortField('');
        setTicketSortOrder('asc');
        setMsgText('');
        setDirtyThresholds(false);
        
    },[]);

    const TextDisplay = ({ text }) => {
        const [displayText, setDisplayText] = React.useState(text);
        console.log('showing msgtext='+text);
        React.useEffect(() => {
          const timer = setTimeout(() => {
            setMsgText('');
          }, 15000); // 15 seconds in milliseconds
    
          return () => clearTimeout(timer);
        }, []);
    
        return <div style={{marginTop:"14px",marginLeft:"20px",fontSize:"18px",fontWeight:"bold"}}>{displayText}</div>;
      };

    const initDashboard = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        const obj = {practices:filterpracs,
            priorities:filterpriors,
            msgtypes:filtermsgtypes,
            statuses:filterstatuses
        }
        console.log('DB Init called obj='+JSON.stringify(obj))
        dataservice.initdashboard(props.globals.useraccountid,obj).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            console.log("Setting MATRIX");
            setMatrix(data.MATRIX);
            setTickets([]);

            console.log('DB Init matrix ready')
        })
    }

    React.useEffect(()=> {
        initDashboard();
    },[refreshneeded])

    const handleRefresh = (e) => {
        initDashboard();
    }

    const handleClear = (e) => {
        setFilterPracs([]);
        setFilterPriors([]);
        setFilterMsgTypes([]);
        setFilterStatuses([]);
        setRefreshNeeded(true);
    }

    const handleSave = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savethresholds(props.globals.useraccountid,matrix.thresholds).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMsgText("Thresholds saved.");

            setDirtyThresholds(false);
        })
    }

    const compareObj = (o1, o2) => {
        var p;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const ShowPractices = () => {
        const handlePracticeChange = (idlist) => {
            //filterpracs.current = idlist;
            setFilterPracs(idlist);
        };

        let obj = [];
        const plist = matrix.totalpractices || [];
        plist.forEach((p)=> {
            obj.push({id:p.practiceid,name:p.name});
        })
        obj.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <GenDropdownCheck items={obj} selectedItems={filterpracs} btntext={'Practice'} cbSelect={handlePracticeChange} ddwidth={480} />
        )
    }

    const ShowPriorities = () => {
        const handlePriorityChange = (idlist) => {
            //filterpriors.current = idlist;
            setFilterPriors(idlist);
        };

        let obj = [];
        let id = 0;
        const plist = matrix.totalpriorities || [];
        for (id in plist) {
            obj.push({id:id,name:plist[id]});
        }
        obj.sort((a, b) => b.id - a.id);
        return (
            <GenDropdownCheck items={obj} selectedItems={filterpriors} btntext={'Priority'} cbSelect={handlePriorityChange} ddwidth={120} />
        )
    }

    const ShowMsgTypes = () => {
        const handleMsgTypeChange = (idlist) => {
            //filtermsgtypes.current = idlist;
            setFilterMsgTypes(idlist);
        };

        let obj = [];
        let id = 0;
        const plist = matrix.totalmsgtypes || [];
        for (id in plist) {
            obj.push({id:id,name:plist[id]});
        }
        obj.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <GenDropdownCheck items={obj} selectedItems={filtermsgtypes} btntext={'Message Type'} cbSelect={handleMsgTypeChange} ddwidth={280} />
        )
    }

    const ShowStatuses = () => {
        const handleStatusChange = (idlist) => {
            //filterstatuses.current = idlist;
            setFilterStatuses(idlist);
        };

        let obj = [];
        let id = 0;
        const plist = matrix.totalstates || [];
        for (id in plist) {
            let nm = plist[id];
            if (nm !== 'CLOSED' && nm !== 'HOLDING') {
                obj.push({id:id,name:nm});
            }
            
        }
        obj.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <GenDropdownCheck items={obj} selectedItems={filterstatuses} btntext={'Status'} cbSelect={handleStatusChange} ddwidth={280} />
        )
    }

    const ShowStatusFocused = () => {
        const statuslist = [[0,'Holding'],[2,'Queued'],[3,'In-Progress'],[5,'Waiting'],[4,'Escalated']];
        const allstats = matrix.allstatuses || {}
        let totalcount = 0;
        let sid = 0;
        for (sid in allstats) {
            const st = allstats[sid];
            totalcount += st[2];
        }
        return (
            <React.Fragment>
                <div className={styles.statusblock}>
                    <div className={styles.statusblockhdr}>
                        <div style={{textAlign:"right",paddingRight:"8px",paddingTop:"5px",fontSize:"18px"}}>Client Filter</div>
                        <div>{ShowPractices()}</div>
                        <div></div>
                        <div style={{textAlign:"right",paddingRight:"8px",paddingTop:"5px",fontSize:"18px"}}>Priority Filter</div>
                        <div>{ShowPriorities()}</div>
                    </div>
                    <div className={styles.statusblockrow} style={{borderBottom:"1px solid gray"}}>
                        <div>Tickets</div>
                        {statuslist.map((st,i)=> {
                            const nm = st[1];
                            return <div key={'sb1_'+i}>{nm}</div>
                        })}
                    </div>
                    <div className={styles.statusblockrow}>
                        <div className={styles.statusblockcell}>{totalcount}</div>
                        {statuslist.map((st,i)=> {
                            const sid = st[0]
                            const nm = st[1]
                            const stat = allstats[sid] || [0,0,0,'',[]];
                            const total = stat[2];
                            const tids = stat[3];
                            return <div className={styles.statusblockcell} key={'sb2_'+i} name={'tt_'+tids}>{total}</div>
                        })}
                    </div>
                    <div className={styles.statusblockrow}>
                        <div>Longest Time</div>
                        {statuslist.map((st,i)=> {
                            const sid = st[0]
                            const stat = allstats[sid] || [0,0,0,'',[]];
                            const longtid = stat[0];
                            const longmin = parseInt(stat[1]);
                            let hrs = (longmin / 60).toFixed(0);
                            let mn = longmin - (hrs * 60);                      
                            return <div className={styles.statusblockcell} key={'sb1_'+i} name={'lt_'+longtid}>{`${hrs}:${mn}`}</div>
                        })}
                    </div>

                </div>
            </React.Fragment>
        )
    }

    const ShowPriorityFocused = () => {
        const prilist = [[4,'OMG'],[3,'High'],[2,'Medium'],[1,'Low'],[0,'No-Action']];
        const allpriors = matrix.allpriorities || {}
        let totalcount = 0;
        let sid = 0;
        for (sid in allpriors) {
            const st = allpriors[sid];
            totalcount += st[2];
        }
        return (
            <React.Fragment>
                <div className={styles.statusblock}>
                    <div className={styles.statusblockhdr}>
                        <div style={{textAlign:"right",paddingRight:"8px",paddingTop:"5px",fontSize:"18px"}}>Client Filter</div>
                        <div>{ShowPractices()}</div>
                        <div></div>
                        <div style={{textAlign:"right",paddingRight:"8px",paddingTop:"5px",fontSize:"18px"}}>Status Filter</div>
                        <div>{ShowStatuses()}</div>
                    </div>
                    <div className={styles.statusblockrow} style={{borderBottom:"1px solid gray"}}>
                        <div>Tickets</div>
                        {prilist.map((st,i)=> {
                            const nm = st[1];
                            return <div key={'pb1_'+i}>{nm}</div>
                        })}
                    </div>
                    <div className={styles.statusblockrow}>
                        <div className={styles.statusblockcell}>{totalcount}</div>
                        {prilist.map((st,i)=> {
                            const sid = st[0]
                            const nm = st[1]
                            const stat = allpriors[sid] || [0,0,0,'',[]];
                            const total = stat[2];
                            const tids = stat[3];
                            return <div className={styles.statusblockcell} key={'pb2_'+i} name={'tt_'+tids}>{total}</div>
                        })}
                    </div>
                    <div className={styles.statusblockrow}>
                        <div>Longest Time</div>
                        {prilist.map((st,i)=> {
                            const sid = st[0]
                            const stat = allpriors[sid] || [0,0,0,'',[]];
                            const longtid = stat[0];
                            const longmin = parseInt(stat[1]);
                            const hrs = (longmin / 60).toFixed(0);
                            const mn = longmin - (hrs * 60);
                            return <div className={styles.statusblockcell} key={'pb1_'+i} name={'lt_'+longtid}>{`${hrs}:${mn}`}</div>
                        })}
                    </div>

                </div>
            </React.Fragment>
        )
    }

    const readTickets = (ticklist=null) => {
        if (!ticklist) ticklist = selectedtids;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticklist(props.globals.useraccountid,ticklist).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            let tlist = JSON.parse(JSON.stringify(data.TICKETS || []));
            const now = new Date();
            tlist.forEach((t)=> {
                const dt = new Date(t.eventdate || null);
                const tm = Math.abs(now.getTime() - dt.getTime());
                const m = Math.floor(tm / (1000 * 60));
                t.age = m;
            })
            setTickets(tlist);
            setShowTickets(true);
        })
    }

    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setTicket(data.TICKET);
            const wf = data.TICKET.workflow || [];
            wf.forEach((ws)=> {
                if (ws.buttontext.toLowerCase()==='in-progress') {
                    setIPState(ws.workflowstateid);
                }
            })
            setShowTicket(true);
        })
    };

    const ShowSelectedTickets = () => {
        if (tickets.length === 0) {
            return (
                <React.Fragment>
                    <div style={{marginTop:"30px",marginLeft:"18px"}}>Click on ticket numbers to display ticket information</div>
                </React.Fragment>
            )
        }

        if (ticketsortfield.length > 0) {
            tickets.sort((a,b)=> {
                const fldA = a[ticketsortfield];
                const fldB = b[ticketsortfield];
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return ticketsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = ticketsortfield;
            let torder = ticketsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setTicketSortField(fld);
            setTicketSortOrder(torder);
        }
        const formatMinutesToHoursMinutes = (totalMinutes) => {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours}:${minutes}`;
          }

        return (
            <React.Fragment>
                <div className={styles.listrowhdr} style={{marginTop:"20px"}}>
                    <div id="ticketid" onClick={handleSortClick}>Ticket #{ticketsortfield==='ticketid' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='ticketid' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="msgname" onClick={handleSortClick}>Message Type{ticketsortfield==='msgname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='msgname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="priority" onClick={handleSortClick}>Priority{ticketsortfield==='priority' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='priority' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="createdate" onClick={handleSortClick}>Created Date{ticketsortfield==='createdate' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='createdate' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="eventname" onClick={handleSortClick}>State{ticketsortfield==='eventname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='eventname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="age" onClick={handleSortClick}>Time in State{ticketsortfield==='age' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='age' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="firstname" onClick={handleSortClick}>First Name{ticketsortfield==='firstname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='firstname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="lastname" onClick={handleSortClick}>Last Name{ticketsortfield==='lastname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='lastname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="dob" onClick={handleSortClick}>DOB{ticketsortfield==='dob' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='dob' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="callbackphone" onClick={handleSortClick}>Phone{ticketsortfield==='callbackphone' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='callbackphone' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="pracname" onClick={handleSortClick}>Practice{ticketsortfield==='pracname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='pracname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                </div>
                <div style={{height:"10px"}}></div>
                {tickets.map((entry,i) => {
                    const ptage = calculateAgeInYears(entry.dob);
                    let phones = "";
                    let bg = null;
                    if (entry.holding > 0 && holding===0) {
                        bg = "yellow";
                    }
                    let rowbg = "white";
                    if (entry===selectedEntry) {
                        rowbg = "lightgreen";
                    }
                    if (entry.callbackphone.length > 0) {
                        phones = formatPhoneNumber(entry.callbackphone);
                    }
                    if (entry.secondaryphone.length > 0) {
                        if (phones.length > 0) phones += " , "
                        phones += formatPhoneNumber(entry.secondaryphone);
                    }
                    return (
                        <div key={"phone_"+i} className={styles.listrow} onClick={()=>handleEntryClick(entry)}>
                            <div>{entry.ticketid}</div>
                            <div>{entry.msgname}</div>
                            <div style={{backgroundColor:bg}}>{PRIORITIES[entry.priority]}</div>
                            <div>{Date12(entry.createdate)}</div>
                            <div>{entry.eventname}</div>
                            <div>{formatMinutesToHoursMinutes(entry.age)}</div>
                            <div className={styles.gridcell}>{entry.firstname}</div>
                            <div className={styles.gridcell}>{entry.lastname}</div>
                            <div>{DateOnly(entry.dob)} ({ptage})</div>
                            <div>{phones}</div>
                            <div className={styles.gridcell}>{entry.pracname}</div>
                        </div>
                    )
                    
                })}
            </React.Fragment>
        )
    }

    const handleChange = React.useCallback((e) => {
        const nm = e.target.name;
        //const v = e.target.value;
        console.log("HC: "+e.target.value+", NM: "+nm);
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        console.log("HC Val: "+numericValue);
        const sa = nm.split('_');
        if (sa.length > 5) {
            let newmatrix = JSON.parse(JSON.stringify(matrix));
            const primatrix = newmatrix.primatrix || {};
            const mtmatrix = newmatrix.mtmatrix || {};
    
            const thres = newmatrix.thresholds || {}
            const thres1 = thres[1] || [];
            const thres2 = thres[2] || [];
            console.log("matrix len="+Object.keys(matrix).length);
            console.log("newmatrix len="+Object.keys(newmatrix).length);
            console.log("thres len="+Object.keys(thres).length);
            console.log("thres1 len="+thres1.length);
            console.log("thres2 len="+thres2.length);
            let tblid = parseInt(''+sa[1]);
            let y = sa[2];
            const yt = parseInt(''+sa[3]);
            let x = sa[4];
            const xt = parseInt(''+sa[5]);
            y = y > 999 ? y-=1000 : y > 100 ? y-=100 : y;
            x = x > 999 ? x-=1000 : x > 100 ? x-=100 : x;
            const yval = '' + y;
            const xval = '' + x;
            const maxthres = tblid==1 ? thres1 : thres2;
            console.log("maxthres len="+maxthres.length);
            console.log("tblid="+tblid);

            maxthres.every((t)=> {
                if (''+t.y===yval && t.ytyp===yt && ''+t.x===xval && t.xtyp===xt) {
                    const oldv = t.timeout;
                    t.timeout = numericValue;
                    setMatrix(newmatrix);
                    if (numericValue !== oldv) {
                        setDirtyThresholds(true);
                    }
                    return false;
                }
                return true;
            })
        }
    },[matrix]);


    const EditThresholds = () => {
        const wfstates = {0:'Holding',2:'Queue',3:'In-Progress',4:'Escalated',7:'Reserved',9:'Waiting'};
        const mtypes = {7:'Office Msg',16:'Appt Request',17:'Appt Cancel',18:'Appt Inquiry',20:'Appt Reschedule'};
        const wfstates2 = {0:'Holding',2:'Queue',3:'In-Progress',4:'Escalated',7:'Reserved',9:'Waiting',1004:'OMG',1003:'High',1002:'Medium',1001:'Low',1000:'No-Action'};

        let newmatrix = JSON.parse(JSON.stringify(matrix));
        const primatrix = newmatrix.primatrix || {};
        const mtmatrix = newmatrix.mtmatrix || {};

        const thres = newmatrix.thresholds || {}
        const thres1 = thres[1] || [];
        const thres2 = thres[2] || [];


        const findThres = (tblid,y,yt,x,xt) => {
            let result = 100000;
            if (y > 999) {
                y -= 1000;
            } else {
                if (y > 100) {
                    y -= 100;
                }
            }
            if (x > 999) {
                x -= 1000;
            } else {
                if (x > 100) {
                    x -= 100;
                }
            }
            let yval = '' + y;
            let xval = '' + x;    
            const thres = matrix.thresholds || {}
            const thres1 = thres[1] || [];
            const thres2 = thres[2] || [];
            const maxthres = tblid==1 ? thres1 : thres2;
            maxthres.every((t)=> {
                if (''+t.y===yval && t.ytyp===yt && ''+t.x===xval && t.xtyp===xt) {
                    result = t.timeout;
                    return false;
                }
                return true;
            })
            return result;
        }
        const sortedEntries = Object.entries(primatrix).sort((a,b)=>b[0]-a[0]);
        return (
            <React.Fragment>
                <div className={styles.matrixblock}>
                    <div className={styles.matrixrow}>
                        <div></div>
                        {Object.entries(wfstates).map(([k,nm])=> {
                            return <div style={{userSelect:"none",paddingLeft:"10px"}} key={k}>{nm}</div>
                        })}
                    </div>
                    {sortedEntries.map(([prid,prow])=> {
                        return <div className={styles.matrixrow} key={prid}>
                            <div style={{userSelect:"none",paddingLeft:"6px"}}>{PRIORITIES[prid]}</div>
                            {Object.entries(prow).map(([wfid,tidlst])=> {
                                const tl = tidlst.length;
                                const ids = 'tid_1_'+prid+'_1_'+wfid+'_2';
                                let showalert = false;
                                const thresvalue = findThres(1,prid,1,wfid,2);
                                return <div id={ids} className={styles.matrixblockcell} style={{padding:"0 0 0 14px"}} key={wfid}>
                                    <input type="text" style={{width:"80%"}} name={ids} onChange={handleChange} maxLength={10} value={thresvalue} />
                                    </div>
                            })}
                        </div>
                    })}
                </div>
                <div className={styles.mtmatrixblock} style={{marginTop:"16px"}}>
                    <div className={styles.mtmatrixrow}>
                        <div></div>
                        {Object.entries(wfstates2).map(([k,nm],i)=> {
                            if (i===6) {return <React.Fragment key={k}><div></div><div style={{paddingLeft:"15px",userSelect:"none"}}>{nm}</div></React.Fragment>}
                            else {return <div style={{userSelect:"none",paddingLeft:"10px"}} key={k}>{nm}</div>}
                        })}
                    </div>
                    {Object.entries(mtmatrix).map(([prid,prow])=> {
                        return <div className={styles.mtmatrixrow} key={prid}>
                            <div style={{userSelect:"none",paddingLeft:"6px"}}>{mtypes[prid]}</div>
                            {Object.entries(prow).map(([wfid,tidlst],i)=> {
                                const tl = tidlst.length;
                                let xtyp = 2;
                                if (i > 5) {
                                    xtyp = 1;
                                }
                                const ids = 'tid_2_'+prid+'_3_'+wfid+'_'+xtyp;

                                const thresvalue = findThres(2,prid,3,wfid,xtyp);

                                return (
                                    <React.Fragment key={wfid}>
                                        {i===6 && <div></div>}
                                        <div id={ids} className={styles.matrixblockcell} style={{padding:"0 0 0 14px"}}>
                                            <input type="text" style={{width:"80%"}} name={ids} onChange={handleChange} value={thresvalue} />
                                            </div>
                                    </React.Fragment>
                                
                                )
                            })}
                        </div>
                    })}
                </div>
            </React.Fragment>
        )

    }

    const ShowMsgMatrix = () => {
        const mtypes = {7:'Office Msg',16:'Appt Request',17:'Appt Cancel',18:'Appt Inquiry',20:'Appt Reschedule'};
        const wfstates = {0:'Holding',2:'Queue',3:'In-Progress',4:'Escalated',9:'Waiting',7:'Reserved',1004:'OMG',1003:'High',1002:'Medium',1001:'Low',1000:'No-Action'};
        const mtmatrix = matrix.mtmatrix || {};
        const thres = matrix.thresholds || {}
        const maxthres = thres[2] || [];
        //const maxqt = matrix.maxqueuetime;

        const findThres = (y,yt,x,xt) => {
            let result = 100000;
            if (y > 999) {
                y -= 1000;
            } else {
                if (y > 100) {
                    y -= 100;
                }
            }
            if (x > 999) {
                x -= 1000;
            } else {
                if (x > 100) {
                    x -= 100;
                }
            }
            let yval = '' + y;
            let xval = '' + x;
            maxthres.every((t)=> {
                if (''+t.y===yval && t.ytyp===yt && ''+t.x===xval && t.xtyp===xt) {
                    result = t.timeout;
                    return false;
                }
                return true;
            })
            return result;
        }

        const handleMTSelect = (e) => {
            const name = e.target.id;
            const sa = name.split('_');
            if (sa.length > 2) {
                const prid = sa[1];
                const wfid = sa[2];
                const prow = mtmatrix[prid] || {};
                const tidlst = prow[wfid] || []
                
                if (tidlst.length > 0) {
                    let tcklist = [];
                    tidlst.forEach(tuple=> {
                        let tid = tuple[0];
                        let tm = tuple[1];
                        tcklist.push(tid);
                        
                    })
                    const tickparm = tcklist.join(',');
                    setSelectedTids(tickparm);
                    
                    setShowTickets(false);
                    readTickets(tickparm);
                } else {
                    setShowTickets(false);
                }
            }
        }

        return (
            <React.Fragment>
                <div className={styles.mtmatrixblock} style={{marginTop:"16px"}}>
                    <div className={styles.mtmatrixrow}>
                        <div></div>
                        {Object.entries(wfstates).map(([k,nm],i)=> {
                            if (i===6) {return <React.Fragment key={k}><div></div><div style={{paddingLeft:"6px",userSelect:"none"}}>{nm}</div></React.Fragment>}
                            else {return <div style={{userSelect:"none"}} key={k}>{nm}</div>}
                        })}
                    </div>
                    {Object.entries(mtmatrix).map(([prid,prow])=> {
                        return <div className={styles.mtmatrixrow} key={prid}>
                            <div style={{userSelect:"none",paddingLeft:"6px"}}>{mtypes[prid]}</div>
                            {Object.entries(prow).map(([wfid,tidlst],i)=> {
                                const tl = tidlst.length;
                                const ids = 'tid_'+prid+'_'+wfid;
                                let xtyp = 2;
                                if (i > 5) {
                                    xtyp = 1;
                                }
                                let showalert = false;
                                const maxthres = findThres(prid,3,wfid,xtyp);
                                tidlst.forEach((tid) => {
                                    if (tid[1] > maxthres) showalert = true;
                                })
                                if (showalert) {
                                    return (
                                        <React.Fragment key={wfid}>
                                            {i===6 && <div></div>}
                                            <div id={ids} onClick={handleMTSelect} className={styles.matrixblockcellred}>{tl}</div>
                                        </React.Fragment>
                                    )
                                }
                                return (
                                    <React.Fragment key={wfid}>
                                        {i===6 && <div></div>}
                                        <div id={ids} onClick={handleMTSelect} className={styles.matrixblockcell} style={{padding:"0 0 0 14px"}}>{tl}</div>
                                    </React.Fragment>
                                
                                )
                            })}
                        </div>
                    })}
                </div>
            </React.Fragment>
        )

    }


    const ShowTicketMatrix = () => {
        const wfstates = {0:'Holding',2:'Queue',3:'In-Progress',4:'Escalated',9:'Waiting',7:'Reserved'};
        const primatrix = matrix.primatrix || {};
        
        const thres = matrix.thresholds || {}
        const maxthres = thres[1] || [];
        console.log("ShowTicketMatrix");
        const findThres = (y,x) => {
            let result = 100000;
            let yval = '' + y;
            let xval = '' + x;
            maxthres.every((t)=> {
                if (''+t.y===yval && ''+t.x===xval) {
                    result = t.timeout;
                    return false;
                }
                return true;
            })
            return result;
        }

        const handleSelect = (e) => {
            const name = e.target.id;
            const sa = name.split('_');
            if (sa.length > 2) {
                const prid = sa[1];
                const wfid = sa[2];
                const prow = primatrix[prid] || {};
                const tidlst = prow[wfid] || []
                
                if (tidlst.length > 0) {
                    let tcklist = [];
                    
                    tidlst.forEach(tuple=> {
                        let tid = tuple[0];
                        let tm = tuple[1];
                        tcklist.push(tid);
                        
                    })
                    const tickparm = tcklist.join(',');
                    setSelectedTids(tickparm);
                    
                    setShowTickets(false);
                    readTickets(tickparm);
                } else {
                    setShowTickets(false);
                }
            }
        }
        const sortedEntries = Object.entries(primatrix).sort((a,b)=>b[0]-a[0]);
        return (
            <React.Fragment>
                <div className={styles.matrixblock}>
                    <div className={styles.matrixrow}>
                        <div></div>
                        {Object.entries(wfstates).map(([k,nm])=> {
                            return <div style={{userSelect:"none"}} key={k}>{nm}</div>
                        })}
                    </div>
                    {sortedEntries.map(([prid,prow])=> {
                        return <div className={styles.matrixrow} key={prid}>
                            <div style={{userSelect:"none",paddingLeft:"6px"}}>{PRIORITIES[prid]}</div>
                            {Object.entries(prow).map(([wfid,tidlst])=> {
                                const tl = tidlst.length;
                                const ids = 'tid_'+prid+'_'+wfid;
                                let showalert = false;
                                const maxthres = findThres(prid,wfid);
                                tidlst.forEach((tid) => {
                                    if (tid[1] > maxthres) showalert = true;
                                })
                                if (showalert) {
                                    return <div id={ids} onClick={handleSelect} className={styles.matrixblockcellred} key={wfid}>{tl}</div>
                                }
                                return <div id={ids} onClick={handleSelect} className={styles.matrixblockcell} style={{padding:"0 0 0 14px"}} key={wfid}>{tl}</div>
                            })}
                        </div>
                    })}
                </div>
            </React.Fragment>
        )
        
    }

    const ShowClientFocused = () => {
        const practices = matrix.practices || {};
        let praclist = [];
        let pid = 0;
        for (pid in practices) {
            const practice = practices[pid];
            const pracname = practice.name || '';
            const stats = practice.statuses || {2:{'longest':(0,0),'thestatus':[0,0]}};
            const queue = stats[2];
            const longest = queue.longest || (0,0);
            const longtid = longest[0];
            const longmin = parseInt(longest[1]);
            const hrs = (longmin / 60).toFixed(0);
            const mn = longmin - (hrs * 60);
            const alltids = queue.thestatus || [];
            const totaltids = alltids.length;
            praclist.push([pracname,totaltids,hrs+':'+mn,alltids])
        }

        return (
            <React.Fragment>
                <div className={styles.statusblock}>
                    <div className={styles.statusblockhdr}>
                        <div style={{textAlign:"right",paddingRight:"8px",paddingTop:"5px",fontSize:"18px"}}>Msg Type Filter</div>
                        <div>{ShowMsgTypes()}</div>
                        <div></div>
                        <div style={{textAlign:"right",paddingRight:"8px",paddingTop:"5px",fontSize:"18px"}}>Priority Filter</div>
                        <div>{ShowPriorities()}</div>
                    </div>
                    <div className={styles.statusblockrow2} style={{borderBottom:"1px solid gray"}}>
                        <div>Client</div>
                        <div>Total Tickets</div>
                        <div>Holding</div>
                        <div>Longest Hold</div>
                        <div>Queue</div>
                        <div>Longest Queue</div>
                        <div>Worked Today</div>
                        <div>Active Agents</div>
                    </div>
                    {praclist.map((p,i)=> {
                        return (
                            <div key={'pl_'+i} className={styles.statusblockrow2}>
                                <div>{p[0]}</div>
                                <div>{p[1]}</div>
                                <div>0</div>
                                <div>0:0</div>
                                <div>{p[1]}</div>
                                <div>{p[2]}</div>
                                <div>0</div>
                                <div></div>
                            </div>
                        )
                    })}
                    
                </div>
            </React.Fragment>
        )
    }

    const ticketOnClose = (ticketid,stateid,priority) => {
        setShowTicket(false);
    }

    if (!props.editthresholds) {
        return (
            <React.Fragment>
                <div>
                    <div className={styles.title}>Ticket Dashboard
                        <span style={{marginLeft:"80px"}}><button style={{height:"26px",fontSize:"16px"}} onClick={handleRefresh}>Apply Filters</button></span>
                        <span style={{marginLeft:"20px"}}><button style={{height:"26px",fontSize:"16px"}} onClick={handleClear}>Clear Filters</button></span>
    
                    </div>
                    <div style={{marginLeft:"20px",width:"94%",display:"flex",borderBottom:"1px solid black"}}>
                        <div style={{marginLeft:"10px",marginTop:"4px"}}>Filters:</div> 
                        <div style={{marginLeft:"10px"}}>{ShowPractices()}</div>
                        <div style={{marginLeft:"14px"}}>{ShowPriorities()}</div>
                        <div style={{marginLeft:"14px"}}>{ShowMsgTypes()}</div>
                        <div style={{marginLeft:"14px"}}>{ShowStatuses()}</div>
                    </div>
                    <div style={{marginLeft:"20px",marginTop:"10px"}}>{ShowTicketMatrix()}</div>
                    <div style={{marginLeft:"20px",marginTop:"10px"}}>{ShowMsgMatrix()}</div>
                    {showtickets && ShowSelectedTickets()}
                    {!showtickets && <div style={{marginTop:"30px",marginLeft:"18px"}}>Click on ticket numbers to display ticket information</div>}
                    {showticket && <TicketDetail globals={props.globals} methods={props.methods} allowpriorityedit={false} disableworkflow={true} ticket={ticket} doClose={ticketOnClose} />}
                </div>
            </React.Fragment>
        )
    } else {
        
        return (
        <React.Fragment>
            <div>
                <div className={styles.title}>Ticket Thresholds
                    <span style={{marginLeft:"80px"}}><button style={{height:"26px",fontSize:"16px"}} disabled={!dirtythres} onClick={handleSave}>Save All</button></span>
                </div>
                {EditThresholds()}
                <TextDisplay text={msgtext} />
                <div style={{marginTop:"30px",marginLeft:"18px"}}>Values are in Minutes</div>
            </div>
        </React.Fragment>
        )
    }
}

export default TicketDashboard;
