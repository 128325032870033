
import React from "react";
import {useNavigate} from 'react-router-dom'; 
import { createBrowserHistory } from "history"
import logo1 from "../src/assets/logo1.svg"
import logo2 from "../src/assets/logo2.svg"
import {
  InputDropdown,
  BadgeBasic,
  AlertTriangle,
  Bell,
  Settings,
  HelpCircle,
  User,
  Button,
  UserState
} from "./components";
import styles from "./HeaderInternal.module.scss";
import {TEST_NOTICE, APP_VERSION} from './AppConstants';
import { AutoComplete } from "./components";
import { DataService } from "./store/DataService";

const HeaderInternal = (props) => {
  const [pracdata,setPracData] = React.useState([]);



  React.useEffect(() => {
      const dataservice = new DataService({guid:props.globals.sessionguid});

      if (props.globals.practicerefresh) {
        props.methods.SetPracticeRefresh(false);
      }
      dataservice.getactivepractices().then((data) => {
          setPracData(data);
      })

  },[props.globals.sessionguid,props.globals.practicerefresh])


  const ShowPractices = () => {
      const praclist = pracdata.map((p,i) => {
          return {id:p.practiceid,name:p.name.trim(),acctnums:p.acctnums,anlist:p.anlist}
      })
      const doChange = (v,anum='') => {
        const p = praclist.find(prac => prac.name===v);

        let pobj = {practiceid:0,locationid:0,practiceaccount:{},provideraccount:{},practice:{name:v}}
        if (p) {
          pobj.practiceid = p.id;
          
          if (anum.length) {
            for (let i=0;i<p.anlist.length;i++) {
              let obj = p.anlist[i];
              if (obj.anum===anum) {
                pobj.locationid = obj.locid;
                break;
              }
            }
          }

          const dataservice = new DataService({guid:props.globals.sessionguid});
          dataservice.getactivepractices(p.id,1).then((data) => {
              if (data.length) {
                let practice = data[0];
                let acclist = practice.pracdetail.account;
                pobj.practice = practice;
                if (acclist.length) {
                  let ilocid = parseInt(pobj.locationid);
                  if (ilocid < 1) {
                    ilocid = parseInt(practice.primarylocationid || "0");
                  }
                  pobj.practiceaccount = acclist[0];
                  acclist.every((acct)=> {
                    if (acct.locationid===ilocid && acct.accounttype===2) {
                      pobj.provideraccount = acct;
                      return false;
                    }
                    return true;
                  })
                }
                props.methods.SetObject(pobj);
              }
          })
        } else {
          props.methods.SetObject(pobj);
        }

      }
      const rdonly = window.location.pathname !== '/';
      return (
          <div className={styles.pracselect}>
              <div>
                  <AutoComplete
                  placeholder="Enter Practice Name or Number"
                  defvalue={props.globals.practice.name}
                  suggestions={praclist}
                  globals={props.globals} 
                  methods={props.methods} 
                  readonly={rdonly}
                  inputwidth="400px"
                  sugwidth="400px"
                  update={doChange}
                  />
              </div>
          </div>
      )
  }


  const history = useNavigate();
  const goHome = (e) => {
    history("/");
  }
  return (
    <div className={styles.headerInternal}>
      <div className={styles.frame251}>
        <div className={styles.ptSourceLogoTwo} onClick={goHome}>
          <img
            alt=""
            className={styles.ptSourceLogo}
            //src="https://static.overlay-tech.com/assets/47e5c814-d87f-463e-9fa7-a553b3bee33d.svg"
            src={logo1}
          />
          <img
            alt=""
            className={styles.portal}
            //src="https://static.overlay-tech.com/assets/797642e3-d576-4cba-831d-9ab1e1f2c28a.svg"
            src={logo2}
          />
          
        </div>
        <div style={{marginLeft:"20px",color:"red",fontSize:"32px",fontWeight:"bold"}}>{TEST_NOTICE}</div>
        <ShowPractices />
        
        {APP_VERSION !== 1 &&
        <div className={styles.frame252}>
          <InputDropdown className={styles.inputDropdown} />
          <div className={styles.frame275}>
            <div className={styles.warningIconGroup}>
              <AlertTriangle className={styles.alertTriangle} />
              <BadgeBasic className={styles.badgeBasic} />
            </div>
            <div className={styles.alertIconGroup}>
              <Bell className={styles.bell} />
              <BadgeBasic className={styles.badgeBasicTwo} />
            </div>
            <div className={styles.alertIconGroup}>
              <Settings className={styles.settings} />
              <BadgeBasic className={styles.badgeBasicTwo} />
            </div>
            <div className={styles.alertIconGroup}>
              <HelpCircle className={styles.helpCircle} />
            </div>
          </div>
          <UserState className={styles.inputPulldown} />
          <div className={styles.frame283}>
            <div className={styles.avatar}>
              <User className={styles.user} />
            </div>
            <p className={styles.text}>{props.globals.name}</p>
          </div>
          <Button globals={props.globals} methods={props.methods} />
        </div>
        }
      {APP_VERSION === 1 && 
        <div className={styles.frame252}>
          <div className={styles.frame283}>
            <div className={styles.avatar}>
              <User className={styles.user} />
            </div>
            <p className={styles.text}>{props.globals.name}</p>
          </div>
          <Button globals={props.globals} methods={props.methods} />
        </div>
      }
      </div>
    </div>
  );
};

export default HeaderInternal;